import React, { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IoIosCheckmarkCircle } from "react-icons/io";
import useTitanApp from "../../../hooks/useTitanApp";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import {
  timelineEventTypeDescription,
  timelineEventTypes,
} from "../../../enum/timelineEventTypes";
import { GoPencil } from "react-icons/go";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { getFormatDate24Hrs } from "../../../utils/getFormatDate24Hrs";

export default function NMSEventsGrouped({ data, createdbyName }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  const [expanded, setExpanded] = useState({});

  const handleChange = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-thumb": {
      width: 15,
      backgroundColor: "yellow", // Change to the color you desire for active state
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
      "&.Mui-disabled": {
        "& .MuiSwitch-thumb": {
          backgroundColor: "grey", // Change to the color you desire for disabled state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,0.05)",
      boxSizing: "border-box",
    },
  }));

  const groupedData = data?.elements?.reduce((acc, item) => {
    if (!acc[item.name]) {
      acc[item.name] = [];
    }
    acc[item.name].push(item);
    return acc;
  }, {});

  return (
    <TimelineItem>
      <TimelineSeparator>
        <IoCheckmarkCircleOutline
          size={30}
          color={darkMode ? "#f2f2f2" : "#0a0a0a"}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <h6>
          <span className="typeHeading">{timelineEventTypes[data?.event]}</span>{" "}
          <span className="type">
            {timelineEventTypeDescription[data?.event]}
          </span>{" "}
          <span className="caller_name">{createdbyName}</span>
          <span className="type">
            {" on "} {getFormatDate24Hrs(data?.createdOn)}
          </span>
        </h6>
        <Accordion
          expanded={expanded["outer"] || false}
          onChange={() => handleChange("outer")}
          className="timeline_accordion"
        >
          {data?.elements?.length > 0 && (
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="heading view-content-heading"
            >
              <p className="view-contents">View contents</p>
            </AccordionSummary>
          )}

          <AccordionDetails>
            {Object.keys(groupedData)?.map((name, groupIndex) => (
              <Accordion
                key={name}
                expanded={expanded[`group_${groupIndex}`] || false}
                onChange={() => handleChange(`group_${groupIndex}`)}
                className="nms_info_accordion"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-content"
                  id="panel-header"
                  className="heading"
                >
                  <div className="header">
                    <IoIosCheckmarkCircle color="#00CC6A" size={20} />
                    <p>{name}</p>
                  </div>
                </AccordionSummary>
                <div className="inner-most">
                  {groupedData[name]?.map((item, index) => (
                    <div key={index}>
                      <div className="header">
                        <p>{item.title}</p>
                      </div>
                      <div className="list_box_wrapper">
                        {Array.isArray(item?.listItems) &&
                        item?.listItems?.length > 0 ? (
                          item?.listItems?.map((listItems, index) => (
                            <div
                              className={`list_box ${
                                listItems?.type !== "Radio"
                                  ? "list_box_alt"
                                  : ""
                              }`}
                            >
                              {listItems?.type === "Radio" ? (
                                <p>{listItems?.label}</p>
                              ) : (
                                <div>
                                  <div className="d-flex">
                                    <GoPencil size={12} color="#00ca69" />
                                    <p>{createdbyName}</p>
                                  </div>
                                  <div>
                                    <p className="note_text">
                                      {listItems?.value}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {listItems?.type === "Radio" ? (
                                <AntSwitch checked={listItems?.value} />
                              ) : (
                                <></>
                                // <TextField
                                //   multiline
                                //   rows={4}
                                //   id="outlined-required"
                                //   disabled={true}
                                //   defaultValue={listItems?.value}
                                //   className="textfield"
                                // />
                              )}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
}
