import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Dropdown } from "primereact/dropdown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as AutomateIcon } from "./../../assets/images/automate-icon.svg";
import useTitanApp from "../../hooks/useTitanApp";
import {
  changePharmacyActions,
  setSendInterventionAutomation,
  setSendOrbitAutomation,
  setSendSMSAutomation,
  setSendToInterventionOptions,
  setIsAutomationMenuOpen,
  setSendConsentOnly,
} from "../../dispatchers/pharmacyServiceDispatchers";

export default function ServicesAutomation() {
  const { pharmacyServiceState, appState } = useTitanApp();
  const {
    sendSMSAutomation,
    sendOrbitAutomation,
    sendInterventionAutomation,
    sendToInterventionOptions,
    SendToInterventionDropdownOptions,
    isAutomationMenuOpen,
    sendConsentOnly,
  } = pharmacyServiceState;
  const { currentPharmacyId } = appState;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleConsentChange = (event) => {
    setSendToInterventionOptions(event.value);
    if (event?.value?._id === 0) {
      changePharmacyActions(currentPharmacyId, 3, true);
      changePharmacyActions(currentPharmacyId, 2, false);
      setSendInterventionAutomation(false);
      setSendConsentOnly(true);
    } else {
      changePharmacyActions(currentPharmacyId, 2, true);
      changePharmacyActions(currentPharmacyId, 3, false);
      setSendConsentOnly(false);
      setSendInterventionAutomation(true);
    }
  };

  const handleClick = (event) => {
    setIsAutomationMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsAutomationMenuOpen(false);
    setAnchorEl(null);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleAutoSendSMSChange = (e) => {
    changePharmacyActions(currentPharmacyId, 0, !sendSMSAutomation);
    setSendSMSAutomation(!sendSMSAutomation);
  };

  const handleAutoSendOrbitChange = (e) => {
    changePharmacyActions(currentPharmacyId, 1, !sendOrbitAutomation);
    setSendOrbitAutomation(!sendOrbitAutomation);
  };

  const handleAutoSendInterventionChange = (e) => {
    if (e.target.checked) {
      changePharmacyActions(currentPharmacyId, 3, true);
      setSendConsentOnly(true);
    } else {
      changePharmacyActions(currentPharmacyId, 4, false);
      setSendInterventionAutomation(false);
      setSendConsentOnly(false);
    }
  };

  return (
    <ClickAwayListener>
      <div>
        <Button
          id="fade-button"
          aria-controls={isAutomationMenuOpen ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isAutomationMenuOpen ? "true" : undefined}
          onClick={handleClick}
          className="automation-button"
        >
          <AutomateIcon />
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          className="automation-menu"
          anchorEl={anchorEl}
          open={isAutomationMenuOpen}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem className="menuItem" disabled={true}>
            <AntSwitch
              name="autoSendSMS"
              checked={sendSMSAutomation}
              onChange={handleAutoSendSMSChange}
              disabled={true}
            />
            <div className="content">
              <p>Auto Send SMS</p>
              <p>
                Automatically send the patient an SMS to consent and sign up for
                NMS
              </p>
            </div>
          </MenuItem>
          <MenuItem className="menuItem" disabled={true}>
            <AntSwitch
              name="autoSendOrbit"
              checked={sendOrbitAutomation}
              onChange={handleAutoSendOrbitChange}
              disabled={true}
            />
            <div className="content">
              <p>Auto Send to Orbit</p>
              <p>
                Automatically send NMS to ORBIT for outsourcing and completion
              </p>
            </div>
          </MenuItem>
          <MenuItem className="menuItem">
            <AntSwitch
              name="autoSendIntervention"
              checked={sendInterventionAutomation || sendConsentOnly}
              onChange={handleAutoSendInterventionChange}
            />
            <div className="content consent_dropdown">
              <p>Auto Send to Intervention</p>
              <p className="content_paragraph">
                Automatically send NMS to Intervention for outsourcing and
                completion
              </p>
              {sendInterventionAutomation || sendConsentOnly ? (
                <Dropdown
                  value={sendToInterventionOptions}
                  onChange={handleConsentChange}
                  options={SendToInterventionDropdownOptions}
                  optionLabel="optionName"
                  className="invite-dropdown"
                />
              ) : (
                <></>
              )}
            </div>
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
}
