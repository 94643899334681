export const getAppUrl = (featureSearchType = 6) =>
  `/Application/get?featureSearchType=${featureSearchType}`;
export const getPharmacyServiceUrl = (pharmacyId, serviceId, searchType) =>
  `/ClinicalService/get-by-clinical-service-type?serviceID=${serviceId}&clinicalServiceStatusSearchType=${searchType}&pharmacyID=${pharmacyId}`;
export const getPharmacyClinicalServiceUrl = (pharmacyId) =>
  `/ClinicalService/get-clinical-service-list?pharmacyID=${pharmacyId}`;
export const getPharmacyClinicalServiceCountUrl = (pharmacyId) =>
  `/ClinicalService/get-clinical-service-count-list?pharmacyID=${pharmacyId}`;
export const getClinicalServiceBuilderByServiceIdUrl = (
  pharmacyId,
  serviceId
) =>
  `/ClinicalServiceBuilder/get-clinical-service-Builder-by-service-type?serviceID=${serviceId}&pharmacyID=${pharmacyId}`;
export const getEventsUrl = (pharmacyId, serviceId) =>
  `/CalendarEvent/get-calendar-events-by-clinical-service-type-and-pharmacy-id?serviceID=${serviceId}&pharmacyID=${pharmacyId}`;
export const updateEventUrl = () => "/CalendarEvent/update-calendar-event";
export const getAnalyticsByServicePharmacyDateUrl = (
  pharmacyId,
  serviceId,
  startDate,
  endDate
) =>
  `/Analytics/get-by-clinical-service-pharmacy-and-date?pharmacyID=${pharmacyId}&serviceID=${serviceId}&startDate=${startDate}&endDate=${endDate}`;
export const getAllDocumentsServiceUrl = (pharmacyId, serviceId) =>
  `/Document/list?serviceID=${serviceId}&pharmacyID=${pharmacyId}`;
export const getTeamsRolesUrl = (companyId) =>
  `/Role/get?searchType=4&keyword=${companyId}`;
export const addRoleUrl = () => "/Role/add";
export const getTeamsRoleByIdUrl = (roleId) =>
  `/Role/get?searchType=2&keyword=${roleId}`;
export const getRolesUrl = () => `/Role/get?searchType=1&keyword=''`;
export const getPermissionsUrl = () =>
  `/Role/get-permissions?searchType=1&keyword=323c4288-6cb9-495a-b42c-f8b0cb9f2b47`;
export const updateRoleUrl = () => `/Role/update`;
//clinicalChecksURL start here
export const getPrescriptionIncomingIdsUrl = (pharmacyId, teamId) =>
  `/Prescription/get-incoming-ids?pharmacyID=${pharmacyId}&teamID=${teamId}`;
export const getPrescriptionByIncomingIdUrl = (incomingId, pharmacyId) =>
  `/Prescription/get-prescription-by-incoming?incomingID=${incomingId}&pharmacyID=${pharmacyId}`;
export const getMedicationUrl = (patientId, pharmacyId) =>
  `/PatientMedication/medication-check?patientID=${patientId}&pharmacyID=${pharmacyId}`;
export const getPrescriptionItemChecksUrl = (pharmacyId) =>
  `/Prescription/post-prescription-item-checks?pharmacyID=${pharmacyId}`;
export const getPrescriptionChecksUrl = (pharmacyId) =>
  `/Prescription/post-prescription-checks?pharmacyID=${pharmacyId}`;
export const postMedicationUrl = (pharmacyId) =>
  `/PatientMedication/add?pharmacyID=${pharmacyId}`;
export const getAdditionalEndorsementUrl = (prescribedItemId, pharmacyId) =>
  `/Prescription/get-additional-info?prescribedItemID=${prescribedItemId}&pharmacyID=${pharmacyId}`;
export const setPatientClinicalChecksUrl = (pharmacyId) =>
  `/Prescription/post-prescription?pharmacyID=${pharmacyId}`;
export const getPatientImageUrl = (imageValue, pharmacyId) =>
  `/Prescription/get-prescription-image?pharmacyID=${pharmacyId}&imageURL=${imageValue}`;
//clinicalChecksURL end here
export const documentSaveUrl = () => "/Document/save";
export const getDocumentById = (documentId) =>
  `/Document/get-by-id?documentID=${documentId}`;
export const inviteTeamMemberUrl = (teamId) =>
  `/Teams/invite-member?teamID=${teamId}`;
export const addTeamUrl = () => `/Teams/save-team`;
export const updateTeamUrl = () => `/Teams/update-team`;
export const getTeamsUrl = () => `/Teams/get-teams?searchType=6`;
export const getUserImagesUrl = (teamId) =>
  `/Teams/get-User-by-teamid?teamID=${teamId}&joiningStatus=1`;
export const getGeneralsUrl = () => `/Company/get-user-company`;
export const updateGeneralsDetailsUrl = () => "/Company/update";
export const getPharmaciesByCompanyIdUrl = (companyId) =>
  `/Pharmacies/get-pharmacy?searchType=3&keyword=${companyId}`;
export const getServicesByPharmacyIdUrl = (pharmacyId) =>
  `/Pharmacies/get-services?pharmacyID=${pharmacyId}`;
export const addRooms = () => "/Pharmacies/add-pharmacy-room";
export const getTemplatesUrl = (companyId) =>
  `/ClinicalServiceBuilder/get-templates-by-company?companyID=${companyId}`;
export const saveTemplateUrl = () => "/ClinicalServiceBuilder/add-template";
export const addOrbitSessionUrl = () => "/UserSessions/add-Sessions";
export const updateOrbitSessionUrl = () => "/UserSessions/update-userSession";
export const getClinicalServiceByClinicalServiceIdUrl = (clinicalServiceId) =>
  `/ClinicalService/get-multiple?clinicalServiceID=${clinicalServiceId}`;
export const getClinicalServiceUrl = (pharmacyId, isOrbitUser) =>
  `/ClinicalService/get-random-by-clinical-service-type?serviceCode=1&pharmacyID=${pharmacyId}&isOrbitUser=${isOrbitUser}`;
export const getClinicalServiceFromOrbitUrl = (
  serviceCode,
  sessionId,
  isTestMode
) =>
  `/ClinicalService/get-orbit-by-priority?serviceCode=${serviceCode}&userSessionId=${sessionId}&isTestMode=${isTestMode}`;
export const getTitanPatientUrl = (patientId, pharmacyId, clinicalServiceId) =>
  `/Patient/get-titan-patient?patientID=${patientId}&pharmacyID=${pharmacyId}&clinicalServiceID=${clinicalServiceId}`;
export const getTitanPatientPdsUrl = (pharmacyId, clinicalServiceId) =>
  `/Patient/get-pds?pharmacyID=${pharmacyId}&clinicalServiceID=${clinicalServiceId}`;
export const addNmsUrl = (isOrbitUser) =>
  `/ClinicalService/update?isOrbitUser=${isOrbitUser}`;
export const getFollowUpByServiceIdAndServiceTypeUrl = (
  serviceId,
  serviceType,
  stageNo
) =>
  `/CalendarEvent/get-calendar-events-by-entity-Id-and-type?entityID=${serviceId}&entityType=${serviceType}&stageNo=${stageNo}`;
export const searchDrugUrl = () => `/Search/drug`;
export const searchPatientUrl = () => `/Search/patient`;
export const addFollowUpUrl = () => `/CalendarEvent/add-calendar-event`;
export const getMembersUrl = (companyId) =>
  `/User/get-company-users?companyID=${companyId}`;
export const getServicesUrl = () => `/Service/get`;
export const addMemberUrl = () => "/User/add-user";
export const updateMemberUrl = () => "/User/update-user";
export const getMemberByIdUrl = (memberId) =>
  `/User/get-by-email?userEmail=${memberId}`;
export const saveServiceUrl = () => `/Service/add`;
export const updateServiceUrl = () => `/Service/update`;
export const getServiceTypeByIdUrl = (serviceTypeId) =>
  `/Service/get-by-id?serviceID=${serviceTypeId}`;
export const addpharmacyServiceUrl = (pharmacyId) =>
  `/PharmacyService/add-pharmacy-service?pharmacyID=${pharmacyId}`;
export const getPharmacyIdForOrbitUserUrl = () => `/OrbitSession/get`;
export const getOrbitProfileUrl = () => `/OrbitProfile/get`;
export const saveOrbitProfileUrl = () => `/OrbitProfile/save`;
export const addOrbitAuditUrl = (actionType, actionDate) =>
  `/OrbitAudit/save?actionType=${actionType}&actionDate=${actionDate}`;
export const getOrbitAuditBySessionIdUrl = (searchType, keyword) =>
  `/OrbitAudit/get?searchType=${searchType}&keyword=${keyword}`;
export const getOrbitDashboardUrl = () =>
  "/OrbitDashboard/get-by-orbit-dashboard-by-userEmail";
export const getVoiceJWTUrl = (pharmacyId) =>
  `/Voice/voice-jwt?pharmacyID=${pharmacyId}`;
export const sendSMSUrl = () => "/SMS/send";
export const getSMSUrl = () => "/SMS/get?searchType=1";
export const getConsentDataUrl = (searchType, keyword) =>
  `/SMS/get?searchType=${searchType}&keyword=${keyword}`;
export const addTimelineUrl = () => `/ClinicalService/add-timeline`;
export const addTimelineItemUrl = () => `/ClinicalService/add-timeline-item`;
export const getOrbitSessionDetailUrl = (pharmacyId) =>
  `/OrbitSession/get-orbit-session-rate?pharmacyID=${pharmacyId}`;
export const saveOrbitSessionDetailUrl = () => "/OrbitSession/save";
export const saveConsentFormUrl = (serviceId) =>
  `ClinicalService/update-clinical-service-by-serviceId`;
export const getUserLogUrl = (serviceId) =>
  `/ClinicalService/get-clinical-service-user-log-by-ClinicalServiceId?clinicalServiceID=${serviceId}`;
export const getTimelineUrl = (clinicalServiceID) =>
  `/ClinicalService/get-timeline?clinicalServiceID=${clinicalServiceID}`;
export const savePatientContactUrl = (serviceId, telephone) =>
  `ClinicalService/update-telephone?clinicalServiceID=${serviceId}&telephone=${telephone}`;
export const getIdentityVerificationUrl = () =>
  "/IdentityVerification/get-session-detail";
export const getIdentityVerificationDataBySessionIdUrl = (
  sessionId,
  gphcNumber
) =>
  `/IdentityVerification/get-data-by-sessionId?sessionID=${sessionId}&gphcNumber=${gphcNumber}`;
export const getRecordingByConversationIdUrl = (conversationId) =>
  `/Voice/get-recording?conversationID=${conversationId}`;
export const getUserDataUrl = (userEmail) =>
  userEmail === null || userEmail === "null" || userEmail === ""
    ? `/User/get-by-email`
    : `/User/get-by-email?userEmail=${userEmail}`;
export const updateUserByUserEmailUrl = (userEmail) =>
  `/User/update-user-by-Email?userEmail=${userEmail}`;
export const updateIsActiveByUserEmailUrl = (userEmail, code) =>
  `/User/update-isActive-by-Email?userEmail=${userEmail}&code=${code}`;
export const updateIsActiveByUserEmailUrlCode = (code) =>
  `/Pharmacies/add-pharmacy-by-activation-code?activationCode=${code}`;
export const saveTotalAttemptsUrl = (clinicalServiceId, stageIndex) =>
  `/ClinicalService/add-total-attemtps-by-clinicalServiceId?clinicalServiceId=${clinicalServiceId}&stageIndex=${stageIndex}`;

export const addServiceNotesURL = () => `/ClinicalServiceNotes/add`;

export const getServiceNotesURL = (clinicalServiceId, stageType) =>
  `/ClinicalServiceNotes/get-notes-list?clinicalServiceID=${clinicalServiceId}&stageType=${stageType}`;
export const updateCalendarEventByClinicalServiceIdUrl = (
  rescheduleDate,
  clinicalServiceId,
  stageNo
) =>
  `/CalendarEvent/update-calendar-event-by-ClinicalServiceId?rescheduleDate=${rescheduleDate}&clinicalServiceID=${clinicalServiceId}&stageNo=${stageNo}`;
export const updateRecheduleByClinicalServiceIdUrl = (
  rescheduleDate,
  clinicalServiceId,
  stageNo
) =>
  `/ClinicalServiceNotes/add-reschedule?rescheduleDate=${rescheduleDate}&clinicalServiceID=${clinicalServiceId}&stageNo=${stageNo}`;
export const updateIsVerifyByUserEmailUrl = (userEmail) =>
  `/User/update-isVerify-by-Email?userEmail=${userEmail}`;
export const getServiceProgressURL = (serviceId, pharmacyId) =>
  `/ClinicalService/get-service-progress-by-serviceId?serviceID=${serviceId}&pharmacyID=${pharmacyId}`;
export const getPatientByTelephoneAndStageIndexUrl = (
  telephone,
  value,
  serviceId,
  userSessionId,
  isOrbitUser
) =>
  `/ClinicalService/get-patients-by-telephoneNumber?telephone=${telephone}&stageIndex=${value}&clinicalServiceID=${serviceId}&userSessionID=${userSessionId}&isOrbitUser=${isOrbitUser}`;
export const getGphcNumberUrl = (gphcNumber) =>
  `/User/get-gphc-number?gphcNumber=${gphcNumber}`;
export const updateClaimStatusURL = () => `/Analytics/update-claim-status`;
export const sendToOrbitURL = () => `/OrbitQueue/send`;
export const getEmailByInvitationCodeUrl = (invitationCode) =>
  `/User/get-email-by-invitationCode?invitationCode=${invitationCode}`;
export const getUserForOrbitUrl = () => `/User/get-user-for-orbit`;
export const resendEmailToUserUrl = (userEmail) =>
  `/User/resend-email-to-user?userEmail=${userEmail}`;
export const userChangePasswordUrl = (userEmail) =>
  `/User/user-change-password?userEmail=${userEmail}`;
export const changeSentToOrbitUrl = (serviceId) =>
  `ClinicalService/update-sent-to-orbit?clinicalServiceID=${serviceId}`;
export const removeTeamMemberURL = (teamID, teamMemberID) =>
  `/Teams/remove-team-member?teamID=${teamID}&teamMemberID=${teamMemberID}`;
export const updateTotalAttemptsByClinicalServiceIdIrl = (serviceId) =>
  `/OrbitQueue/update-total-orbit-attempts-by-clinicalServiceId?clinicalServiceID=${serviceId}`;
export const getOrbitMonthlyReportUrl = (startDate, endDate) =>
  `/OrbitDashboard/get-monthly-report?startDate=${startDate}&endDate=${endDate}`;
export const getOrbitEarningsUrl = (startDate, endDate) =>
  `/OrbitDashboard/get-orbit-earnings?startDate=${startDate}&endDate=${endDate}`;
export const getOrbitUserSessionsUrl = () =>
  `/OrbitDashboard/get-user-sessions`;
export const getOrbitFollowUpServicesUrl = () =>
  `/OrbitDashboard/get-followup-services`;
export const getOrbitPotentialEarningsUrl = () =>
  `/OrbitDashboard/get-potential-earnings`;
export const getOrbitOtherDetailsUrl = () =>
  `/OrbitDashboard/get-other-details`;
export const userChangeCompanyUrl = (email, type) =>
  `/User/user-change-company?userEmail=${email}&userCompanyChangeType=${type}`;
export const getCompanyNamesUrl = (oldCompanyId, newCompanyId) =>
  `/Company/get-company-names?oldCompanyID=${oldCompanyId}&newCompanyID=${newCompanyId}`;
export const skipOrbitByClinicalServiceIdUrl = (clinicalServiceId, sessionId) =>
  `/OrbitQueue/skip-orbit-by-clinicalServiceId?clinicalServiceID=${clinicalServiceId}&userSessionID=${sessionId}`;
export const backOrbitByClinicalServiceIdUrl = (serviceId) =>
  `/OrbitQueue/back-orbit-by-clinicalServiceId?clinicalServiceID=${serviceId}`;
export const parkOrbitByClinicalServiceIdUrl = (serviceId) =>
  `/OrbitQueue/park-orbit-by-clinicalServiceId?clinicalServiceId=${serviceId}`;
export const getOrbitParkedServicesUrl = (isTestMode) =>
  `/OrbitDashboard/get-parked-orbit?isTestMode=${isTestMode}`;
export const changeOrbitServiceStatusUrl = (serviceId) =>
  `/OrbitQueue/change-status-by-clinicalServiceId?clinicalServiceId=${serviceId}`;
export const getOrbitActiveServicesUrl = (sessionId, isTestMode) =>
  `/OrbitDashboard/get-active-services?userSessionId=${sessionId}&isTestMode=${isTestMode}`;
export const moveInterventionUrl = () =>
  "/ClinicalService/send-to-intervention";
export const getTotalSkipsUrl = (clinicalServiceId, sessionId) =>
  `/OrbitQueue/get-total-skips?clinicalServiceID=${clinicalServiceId}&userSessionID=${sessionId}`;
export const updateLastLoginByEmailUrl = () =>
  `/User/update-lastLogin-by-email`;
export const addTestModeServiceUrl = (telephone, numberOfScripts) =>
  `/KPI/add-test-mode-service?telephone=${telephone}&numberOfScripts=${numberOfScripts}`;
export const getXeroContact = () => `/Xero/get-contact`;
export const upsertXeroContactUrl = () => `/Xero/upsert-contact`;
export const getXeroInvoiceUrl = (invoiceID) =>
  `/Xero/get-invoice-url?invoiceID=${invoiceID}`;
export const updateUserCurrentStatusUrl = (statusType) =>
  `/User/update-user-status?statusType=${statusType}`;
export const updateLogoutByEmailUrl = () => `/User/update-logout-by-email`;
export const checkYotiDataBySessionIdUrl = (sessionId) =>
  `/IdentityVerification/check-yoti-data-by-sessionId?sessionID=${sessionId}`;
export const declineServicesUrl = () => "/ClinicalService/decline-services";
export const rescheduleServicesUrl = (rescheduleDate) =>
  `/ClinicalService/reschedule-services?rescheduleDate=${rescheduleDate}`;
export const getPharmacyActionsUrl = (pharmacyId) =>
  `/Pharmacies/get-pharmacy-actions?pharmacyID=${pharmacyId}`;
export const changePharmacyActionsUrl = (
  pharmacyId,
  pharmacyActionType,
  isAction
) =>
  `/Pharmacies/change-pharmacy-action?pharmacyID=${pharmacyId}&pharmacyActionType=${pharmacyActionType}&isAction=${isAction}`;
export const getPaymentDetailsUrl = () => `/OrbitProfile/get-payment-details`;
export const getPatientPhoneNumberUrl = (clinicalServiceId, patientId) =>
  `/ClinicalService/get-patient-phoneNumber-by-patientId-and-clinicalServiceId?PatientID=${patientId}&clinicalServiceID=${clinicalServiceId}`;
export const changeMemberRoleUrl = (userEmail, companyID, roleID) =>
  `/User/change-role?userEmail=${userEmail}&companyID=${companyID}&roleID=${roleID}`;
export const moveDeclineServiceToClinicalServiceUrl = (clinicalServiceId) =>
  `/ClinicalService/move-decline-service-to-clinicalService?clinicalServiceID=${clinicalServiceId}`;
