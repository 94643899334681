import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputLabel from "../controls/CustomInputLabel";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/Invite.scss";
import { Dropdown } from "primereact/dropdown";
import setinviteTeam from "../../Model/Inviteteam/setinviteTeam";
import { showError } from "../../components/ToastedMessage/ToastedMessage";
import useTitanApp from "../../hooks/useTitanApp";
import {
  changeMemberRole,
  getRoles,
  inviteTeamMember,
  removeTeamMember,
} from "../../dispatchers/teamDispatchers";
import useTitanTeam from "../../hooks/useTitanTeam";
import { useRevalidator, useNavigate } from "react-router-dom";
import { RxCaretDown } from "react-icons/rx";
import { P } from "@sendbird/uikit-react/context-e46fff7b";
import CustomLoader from "../controls/CustomLoader";
import { getApplicationData } from "../../dispatchers/appDispatchers";
import { GrTrash } from "react-icons/gr";
export default function Invite(props) {
  const { show, setShow } = props;
  const revalidator = useRevalidator();
  let navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [{ name: "Can View" }, { name: "Can Edit" }];
  const [emailError, setEmailError] = useState("");
  const { appState } = useTitanApp();
  const { teamState } = useTitanTeam();
  const [isLoading, setIsLoading] = useState(false);
  const { roles, isRolesLoading } = teamState;
  const { currentTeamId, companyId, currentTeam } = appState || {};
  const { teamMembers } = currentTeam;
  useEffect(() => {
    if (show) {
      getRoles().then((data) => {});
    }
  }, [show]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test("emails", "Please enter valid email address", (value) => {
        if (!value) return true;
        const emails = value.split(",");
        const regex =
          /^\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*,\s*)*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/i; // email validation regex
        return emails.every((email) => regex.test(email.trim()));
      })
      .required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });

  const sendInvite = () => {
    if (!formik.values.email) {
      setEmailError("Email fields is required!");
    } else if (formik.touched.email && formik.errors.email) {
      setEmailError("Please type a valid email address!");
    } else if (selectedOption === null) {
      setEmailError("Role is required");
    } else {
      const myArray = formik.values.email.split(",");
      const EmailArray = new setinviteTeam(
        myArray,
        currentTeamId,
        companyId,
        selectedOption
      ).obj;
      inviteTeamMember(EmailArray, currentTeamId)
        .then((data) => {
          if (data === null) {
            showError("Email address already exists!");
          }
          closeModal();
        })
        .catch((data) => {
          showError(data);
        });
    }
  };
  const closeModal = () => {
    setShow(false);
  };
  function handleRoleChange(event, member) {
    setIsLoading(true);
    changeMemberRole({
      userEmail: member?.email,
      companyID: member?.companyID,
      roleID: event?._id,
    }).then((data) => {
      getApplicationData(0);
      setIsLoading(false);
    });
  }
  function removeTeamMemberFromTeam(event, member) {
    setIsLoading(true);
    const { teamID } = currentTeam;
    const { teamMemberID } = member;
    removeTeamMember({ teamID, teamMemberID }).then((data) => {
      setIsLoading(false);
      closeModal();
      getApplicationData(0);
    });
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        centered
        dialogClassName="inviteModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite to {currentTeam?.teamName}</Modal.Title>
        </Modal.Header>
        {isRolesLoading ? (
          <></>
        ) : (
          <>
            {isLoading ? (
              <div className="pharmacy-loader">
                <CustomLoader />
              </div>
            ) : (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <div className="invite_form">
                    <div className="invite_email_div">
                      <CustomInputLabel name="Email" />

                      <div className="inviteList-email">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          className="custom-input"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />

                        <Dropdown
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.value)}
                          options={roles}
                          optionLabel="roleName"
                          placeholder="Select role"
                          className="invite-dropdown"
                        />
                      </div>
                      <div className="error-message">{emailError}</div>
                    </div>

                    <div className="invite-buttons">
                      <CustomButton label="Send Invite" action={sendInvite} />
                    </div>
                  </div>
                </form>
                <div className="team_members_list">
                  {currentTeam?.teamMembers?.map((member, index) => {
                    const defaultSelectedRole = roles.find(
                      (role) => role?._id === member?.roleId
                    );
                    return (
                      <div className="team_members" key={index}>
                        <div className="team_member_name">
                          <p>{member?.userFullName}</p>
                        </div>
                        <div className="team_member_role">
                          <Dropdown
                            onChange={(e) => {
                              handleRoleChange(e?.value, member);
                            }}
                            value={defaultSelectedRole}
                            options={roles}
                            dialogClassName="invite-team-dialog"
                            optionLabel="roleName"
                            placeholder="Select role"
                            className="invite-team-list"
                          />
                          {teamMembers?.length > 1 ? (
                            <button
                              onClick={(e) =>
                                removeTeamMemberFromTeam(e?.value, member)
                              }
                            >
                              <GrTrash size={20} className="icons-color" />
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
