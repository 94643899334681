import React, { memo } from "react";
import ServicesCheckbox from "../ServicesCheckbox";
import {
  getUserLog,
  setListViewGridScrollPosition,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { useNavigate } from "react-router";
import CustomGridListLabels from "./CustomGridListLabels";
import { FaCheck } from "react-icons/fa6";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { HiLockClosed } from "react-icons/hi";
import { LuCheckCircle } from "react-icons/lu";
import { IoCheckmark } from "react-icons/io5";

const CustomPatientRenderer = memo((props) => {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const { processedPDSServices } = pharmacyServiceState || {};
  const navigate = useNavigate();

  const handleRowSelection = () => {
    if (props) {
      const clinicalServiceId =
        props?.node?.allLeafChildren[0]?.data?.clinicalServiceId;
      const sendToOrbit = props?.node?.allLeafChildren[0]?.data?.sendToOrbit;

      if (sendToOrbit === "Yes") {
        props?.setListviewOrbitClinicalServiceId(clinicalServiceId);
        props?.setShowOrbitQueuePopup(true);
      } else {
        getUserLog(clinicalServiceId).then((data) => {
          if (!data?.isActive) {
            navigate(`/nmsintervention/${clinicalServiceId}`, {
              state: {
                serviceType: "ListView",
              },
            });
          }
        });
      }
    }
  };

  const renderContent = () => {
    if (props.node.parent.id === "ROOT_NODE_ID") {
      return (
        <CustomGridListLabels
          label={props.node.key}
          rowData={props?.node?.allLeafChildren}
          smsQueue={props?.smsQueue}
          reSmsQueue={props?.reSmsQueue}
          interventionQueue={props?.interventionQueue}
          declineQueue={props?.declineQueue}
          rescheduleQueue={props?.rescheduleQueue}
          pdsQueue={props?.pdsQueue}
          orbitQueue={props?.orbitQueue}
          setCurrentSmsQueue={props?.setCurrentSmsQueue}
          setCurrentReSmsQueue={props?.setCurrentReSmsQueue}
          setCurrentInterventionQueue={props?.setCurrentInterventionQueue}
          setCurrentDeclineQueue={props?.setCurrentDeclineQueue}
          setCurrentRescheduleQueue={props?.setCurrentRescheduleQueue}
          setCurrentPdsQueue={props?.setCurrentPdsQueue}
          setCurrentOrbitQueue={props?.setCurrentOrbitQueue}
          checkedAllEligible={props?.checkedAllEligible}
          setCheckedAllEligible={props?.setCheckedAllEligible}
          checkedAllConsent={props?.checkedAllConsent}
          setCheckedAllConsent={props?.setCheckedAllConsent}
          checkedAllIntervention={props?.checkedAllIntervention}
          setCheckedAllIntervention={props?.setCheckedAllIntervention}
          checkedAllFollowUp={props?.checkedAllFollowUp}
          setCheckedAllFollowUp={props?.setCheckedAllFollowUp}
          checksCount={props?.checksCount}
          setChecksCount={props?.setChecksCount}
          parentLevel={props?.node?.level}
          childrenAfterFilter={props?.node?.childrenAfterFilter}
          nodeData={props?.node}
          setRowData={props?.setRowData}
        />
      );
    } else {
      return (
        <div className="patient-renderer" onClick={handleRowSelection}>
          <div className="d-flex">
            <ServicesCheckbox
              data={props?.node?.allLeafChildren[0]?.data}
              view="listview"
              smsQueue={props?.smsQueue}
              reSmsQueue={props?.reSmsQueue}
              interventionQueue={props?.interventionQueue}
              declineQueue={props?.declineQueue}
              rescheduleQueue={props?.rescheduleQueue}
              pdsQueue={props?.pdsQueue}
              orbitQueue={props?.orbitQueue}
              setCurrentSmsQueue={props?.setCurrentSmsQueue}
              setCurrentReSmsQueue={props?.setCurrentReSmsQueue}
              setCurrentInterventionQueue={props?.setCurrentInterventionQueue}
              setCurrentDeclineQueue={props?.setCurrentDeclineQueue}
              setCurrentRescheduleQueue={props?.setCurrentRescheduleQueue}
              setCurrentPdsQueue={props?.setCurrentPdsQueue}
              setCurrentOrbitQueue={props?.setCurrentOrbitQueue}
              checksCount={props?.checksCount}
              setChecksCount={props?.setChecksCount}
              childrenAfterFilter={props?.node?.childrenAfterFilter}
              nodeData={props?.node}
              setRowData={props?.setRowData}
              rowData={props?.node?.allLeafChildren}
              parentLevel={props?.node?.level}
            />
            <span className="label">
              <span className="name">{props?.value} </span>
              <span className="age">
                ({props?.node?.allLeafChildren[0]?.data?.age})
              </span>
              {processedPDSServices?.includes(
                props?.node?.allLeafChildren[0]?.data?.clinicalServiceId
              ) && <FaCheck size="22" />}
              {props?.node?.allLeafChildren[0]?.data?.sendToOrbit === "Yes" && (
                <span>
                  <HiLockClosed color={darkMode ? "#fff" : "#000"} size={15} />
                </span>
              )}

              {props.servicesUpdatedData?.includes(
                props?.node?.allLeafChildren[0]?.data?.clinicalServiceId
              ) && <IoCheckmark size="22" className="pds-check" />}
            </span>
          </div>
        </div>
      );
    }
  };

  return renderContent();
});

export default CustomPatientRenderer;
