import React from "react";
import "./../../../../../assets/css/Dashboard/ServicesAccordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTitanApp from "../../../../../hooks/useTitanApp";
import CustomLoader from "../../../../../components/controls/CustomLoader";
import commonData from "../../../../../data/commonData";
import { useNavigate } from "react-router-dom";
import {
  getPharmacyClinicalServices,
  getPharmacyClinicalServicesCount,
  getPharmacyServices,
  getServiceProgress,
  setCurrentServiceId,
  setPharmacyServiceSidebarLoading,
  setServiceTab,
  setServicesNameAndIcons,
  setShowCalender,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomButton from "../../../../../components/controls/CustomButton";

export default function ServicesAccordion() {
  let navigate = useNavigate();
  const params = useParams();
  const { pharmacyId } = params || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  const { serviceIcons } = commonData || {};
  const { pharmacyServiceState, appState } = useTitanApp();
  const {
    isPharmacyServiceSiedebarLoading,
    currentServiceId,
    NhsCount,
    privateCount,
    currentServiceData,
  } = pharmacyServiceState || {};
  const { darkMode, features } = appState || {};
  const { ifClinicalChecksEnabled } = features || {};

  async function setUITabs(id) {
    setServiceTab(0);
    setCurrentServiceId(id);
    setShowCalender(true);
    setPharmacyServiceSidebarLoading(true);
  }

  async function handleSidebarClick(id) {
    setUITabs(id);
    getPharmacyServices(pharmacyId, 0, id, true);
    getServiceProgress(id, pharmacyId);
    getPharmacyClinicalServices(pharmacyId);
    getPharmacyClinicalServicesCount(pharmacyId);
  }

  async function handleSidebarClicks(serviceName, serviceId, serviceIcon) {
    setServicesNameAndIcons(serviceName, serviceIcon, serviceId);
    handleSidebarClick(serviceId, serviceName, serviceIcon);
  }

  const handleChecksClick = () => {
    const url = `/clinicalchecks/${pharmacyId}`;
    navigate(url, {
      state: {
        serviceType: "Kanban",
      },
    });
  };

  if (isPharmacyServiceSiedebarLoading === true) {
    const arrType1 =
      currentServiceData?.length > 0
        ? currentServiceData
        : [{ serviceType: 1 }];

    const arrType2 =
      currentServiceData?.length > 0
        ? currentServiceData
        : [{ serviceType: 2 }];
    return (
      <div className="services_sidebar_container">
        <Accordion defaultExpanded className="services-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Skeleton
              variant="rounded"
              width={100}
              height={16}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </AccordionSummary>
          {arrType1.map((box, index) => {
            switch (box.serviceType) {
              case 1:
                return (
                  <AccordionDetails key={index}>
                    <div className="services_list">
                      {
                        <Skeleton
                          sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                          variant="rounded"
                          height={30}
                          animation="wave"
                        />
                      }
                    </div>
                  </AccordionDetails>
                );
            }
          })}
        </Accordion>
        <Accordion defaultExpanded className="services-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Skeleton
              variant="rounded"
              width={100}
              height={16}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </AccordionSummary>
          {arrType2.map((box, index) => {
            switch (box.serviceType) {
              case 2:
                return (
                  <AccordionDetails key={index}>
                    <div className="services_list">
                      {
                        <Skeleton
                          sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                          variant="rounded"
                          height={30}
                          animation="wave"
                        />
                      }
                    </div>
                  </AccordionDetails>
                );
            }
          })}
        </Accordion>
        {ifClinicalChecksEnabled && (
          <div className="sidebar_skeleton_btn">
            {
              <Skeleton
                sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                variant="rounded"
                height={30}
                width={150}
                animation="wave"
              />
            }
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="services_sidebar_container">
      <Accordion defaultExpanded className="service-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="services_accordion_details-main-heading">
            <p className="service_title_side">NHS</p>
            <p className="services_accordion_details-count">({NhsCount})</p>
          </div>
        </AccordionSummary>
        {currentServiceData?.filter((box) => box.serviceType === 1).length >
        0 ? (
          currentServiceData
            .filter((box) => box.serviceType === 1)
            .map((box, index) => {
              const serviceIcon = serviceIcons[box.name];
              return (
                <AccordionDetails key={index}>
                  <div className="services_list">
                    <div
                      className={`service_item_sidebar title-heading ${
                        currentServiceId === box.serviceID ? "active" : ""
                      }`}
                      style={{
                        color: "grey",
                      }}
                      onClick={() =>
                        handleSidebarClicks(
                          box?.name,
                          box?.serviceID,
                          serviceIcon
                        )
                      }
                    >
                      <div className="services_iconboxService">
                        <div className="services_icon">{serviceIcon}</div>
                        <span className="service_item_title">
                          {box?.name || ""}
                        </span>
                      </div>
                      <span className="itemCount">{box?.total || 0}</span>
                    </div>
                  </div>
                </AccordionDetails>
              );
            })
        ) : (
          <div className="pharmacy-error-message">
            <p>Data not available!</p>
          </div>
        )}
      </Accordion>

      <Accordion defaultExpanded className="service-accordion">
        <AccordionSummary
          expandIcon={
            currentServiceData?.filter((box) => box.serviceType === 2).length >
            0 ? (
              <ExpandMoreIcon />
            ) : (
              <></>
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="services_accordion_details-main-heading">
            <p className="service_title_side">Private</p>
            <p className="services_accordion_details-count">({privateCount})</p>
          </div>
        </AccordionSummary>
        {currentServiceData?.filter((box) => box.serviceType === 2).length >
        0 ? (
          currentServiceData
            .filter((box) => box.serviceType === 2)
            .map((box, index) => (
              <AccordionDetails key={index}>
                <div className="services_list">
                  <div
                    className={`service_item_sidebar title-heading ${
                      currentServiceId === box.serviceID ? "active" : ""
                    }`}
                    style={{
                      color: "grey",
                    }}
                    onClick={() =>
                      handleSidebarClicks(box?.name, box?.serviceID)
                    }
                  >
                    <span className="service_item_title">
                      {box?.name || ""}
                    </span>
                    <span className="itemCount">{box?.total || 0}</span>
                  </div>
                </div>
              </AccordionDetails>
            ))
        ) : (
          <div className="pharmacy-error-message">
            <p>Data not available!</p>
          </div>
        )}
      </Accordion>

      {ifClinicalChecksEnabled && (
        <div>
          <CustomButton
            label="Clinical Checks"
            action={(event) => {
              handleChecksClick();
            }}
          />
        </div>
      )}
    </div>
  );
}
