import * as React from "react";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomLabel from "../controls/CustomLabel";
import { ImWarning } from "react-icons/im";
import SubItem from "./SubItem";
import CustomSwitch from "../controls/CustomSwitch";
import { inputTypes } from "../../enum/inputTypes";
import CustomDropdown from "../controls/CustomDropdown";
import { setCurrentTimelineElements } from "../../dispatchers/nmsDispatchers";
import { timelineClasses } from "@mui/lab";
import NmsServiceSlice from "../../features/NmsServiceSlice/NmsServiceSlice";
import useTitanApp from "../../hooks/useTitanApp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
function CustomAccordion({
  SectionTitle,
  UserControls,
  boxStyling,
  handleChangeModel,
  isCompleted,
  stageStatus,
  darkModeChangedarkModeChange,
  mainSectionTitle,
  groupId,
}) {
  let rowCount = 0;
  const { nmsServiceState } = useTitanApp();
  const { timelineElements, NmsModel, value } = nmsServiceState || {};

  let valueNMS = value;
  useEffect(() => {
    let sortedUserControl = [...UserControls].sort((a, b) => {
      if (a?.type === 5 && b?.type !== 5) {
        return -1;
      } else if (a?.type !== 5 && b?.type === 5) {
        return 1;
      } else {
        return a?.label.localeCompare(b?.label);
      }
    });
    setInputValues(sortedUserControl);
    UserControls?.map((item, index) => {
      if (item.type === 9 && item.value !== "") {
        setAddedTexts((addedTexts) => [...addedTexts, index]);
      }
    });
  }, [UserControls]);
  const [expanded, setExpanded] = React.useState(false);
  const [inputclass, setInputClass] = useState("disable");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [inputValues, setInputValues] = useState(UserControls);
  const [timelineListItems, setTimelineListItems] = useState([]);
  const [switchClass, setSwitchclass] = useState("switch-content-reports");
  const [isExceeded, setIsExceeded] = useState(false);
  function handleChangeColor(isChecked) {
    isChecked
      ? setSwitchclass("switch-content-reports active-switch")
      : setSwitchclass("switch-content-reports");
  }
  let alreadyControlRendered = [];
  const [addedTexts, setAddedTexts] = useState([]);
  const addText = async (index) => {
    if (addedTexts.includes(index)) {
      const updatedNumbers = addedTexts.filter(
        (addedTexts) => addedTexts !== index
      );
      setAddedTexts(updatedNumbers);
      setInputValues((prevInputValues) => {
        const newInputValues = [...prevInputValues];
        newInputValues[index].value = "";
        return newInputValues;
      });
    } else {
      setAddedTexts((addedTexts) => [...addedTexts, index]);
    }
  };

  const handleFormChange = async (index, event, type, labelName, itemValue) => {
    let value;
    if (type === "Radio") {
      const itemValue = event.target.checked ? "true" : "false";
      value = itemValue;

      handleChangeColor(value);

      // Retrieve the existing element index in the timelineElements array
      const existingElementIndex = timelineElements.findIndex(
        (element) =>
          element?.title === SectionTitle && element?.groupId === groupId
      );

      if (itemValue === "false" && existingElementIndex !== -1) {
        // Remove the item if the switch is false and the element exists
        const updatedListItems = timelineElements[
          existingElementIndex
        ].listItems.filter((item) => item.label !== labelName);

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else if (itemValue === "true") {
        // Add the item if the switch is true
        const timelineListItemObj = {
          label: labelName,
          value: itemValue,
          type: type,
        };

        if (existingElementIndex !== -1) {
          // If the title exists, merge the list items
          const updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];

          const updatedTimelineElement = {
            ...timelineElements[existingElementIndex],
            listItems: updatedListItems,
          };

          const updatedTimelineElements = [
            ...timelineElements.slice(0, existingElementIndex),
            updatedTimelineElement,
            ...timelineElements.slice(existingElementIndex + 1),
          ];

          setCurrentTimelineElements(updatedTimelineElements);
        } else {
          // If the title doesn't exist, create a new object
          const timelineElementsObj = {
            name: mainSectionTitle,
            title: SectionTitle,
            value: SectionTitle,
            listItems: [timelineListItemObj],
            elementType: 8,
          };

          const updatedTimelineElements = [
            ...timelineElements,
            timelineElementsObj,
          ];
          setCurrentTimelineElements(updatedTimelineElements);
        }
      }
    } else if (type === "date") {
      value = event.toISOString();

      const timelineElementsObj = {
        name: mainSectionTitle,
        title: value,
        value: value,
        listItems: [],
        elementType: 8,
      };

      const updatedTimelineElements = [
        ...timelineElements,
        timelineElementsObj,
      ];
      setCurrentTimelineElements(updatedTimelineElements);
    } else if (type === "multiText") {
      setIsExceeded(false);
      value = event?.target?.value;
      if (value.length > 4999) {
        setIsExceeded(true);
      }
      const existingElementIndex = timelineElements.findIndex(
        (element) =>
          element?.title === SectionTitle && element?.name === mainSectionTitle
      );
      const timelineListItemObj = {
        label: labelName,
        value: value,
        type: type,
      };
      let updatedListItems;
      if (existingElementIndex !== -1) {
        // If the title exists, merge the list items
        const listitems = timelineElements[existingElementIndex].listItems;
        let otherIndex = listitems.findIndex(
          (item) => item.label === labelName
        );
        if (otherIndex !== -1) {
          updatedListItems = listitems?.map((item, index) => {
            if (index === otherIndex) {
              // Modify the specific element at otherIndex
              return {
                ...item,
                value: value,
              };
            }
            return item;
          });
        } else {
          updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];
        }

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else {
        // If the title doesn't exist, create a new object
        const timelineElementsObj = {
          name: mainSectionTitle,
          title: SectionTitle,
          value: SectionTitle,
          listItems: [timelineListItemObj],
          elementType: 7,
        };

        const updatedTimelineElements = [
          ...timelineElements,
          timelineElementsObj,
        ];
        setCurrentTimelineElements(updatedTimelineElements);
      }
    } else {
      value = event?.target?.value;
      const existingElementIndex = timelineElements.findIndex(
        (element) =>
          element?.title === SectionTitle && element?.name === mainSectionTitle
      );

      let stageName;
      switch (valueNMS) {
        case 0:
          stageName = "Eligible";
          break;
        case 1:
          stageName = "Awaiting consent";
          break;
        case 2:
          stageName = "Intervention";
          break;
        case 3:
          stageName = "Follow up";
          break;

        default:
          break;
      }

      const timelineListItemObj = {
        label: stageName,
        value: value,
        type: type,
      };

      let updatedListItems;

      if (existingElementIndex !== -1) {
        // If the title exists, merge the list items
        const listitems = timelineElements[existingElementIndex].listItems;
        let otherIndex = listitems.findIndex(
          (item) => item.label === stageName
        );
        if (otherIndex !== -1) {
          updatedListItems = listitems?.map((item, index) => {
            if (index === otherIndex) {
              // Modify the specific element at otherIndex
              return {
                ...item,
                value: value,
              };
            }
            return item;
          });
        } else {
          updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];
        }

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else {
        // If the title doesn't exist, create a new object
        const timelineElementsObj = {
          name: mainSectionTitle,
          title: SectionTitle,
          value: SectionTitle,
          listItems: [timelineListItemObj],
          elementType: 7,
        };

        const updatedTimelineElements = [
          ...timelineElements,
          timelineElementsObj,
        ];
        setCurrentTimelineElements(updatedTimelineElements);
      }
    }
    const UserControlObj = await setUserControl(index, value);
    handleChangeModel(SectionTitle, UserControlObj, groupId);
  };

  const setUserControl = async (index, value) => {
    let updatedValues;

    await setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];

      if (newInputValues?.length > 0) {
        newInputValues[index] = { ...newInputValues[index], value };
        updatedValues = [...newInputValues];

        if (
          newInputValues[index]?.isChildControl === true &&
          value === "false"
        ) {
          newInputValues?.forEach((subItem, subItemIndex) => {
            if (
              subItem?.parentUserControlId ===
              newInputValues[index]?.userControlId
            ) {
              newInputValues[subItemIndex] = { ...subItem, value };
              updatedValues = [...newInputValues];
            }
          });
        }
      }
      return newInputValues;
    });

    return updatedValues;
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-thumb": {
      width: 15,
      backgroundColor: "yellow", // Change to the color you desire for active state
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
    },

    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
      "&.Mui-disabled": {
        "& .MuiSwitch-thumb": {
          backgroundColor: "grey", // Change to the color you desire for disabled state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,0.05)",
      boxSizing: "border-box",
    },
  }));
  function showComplete() {
    let complete = false;
    inputValues?.map((item, index) => {
      if (item.value === "true") {
        complete = true;
      }
    });
    return complete;
  }
  function isRequiredAccordion() {
    let isValidate = false;
    let filledControlArray = [];
    const sections = NmsModel?.stages[value]?.template?.templateSections;
    const requiredSection = sections.find(
      (section) =>
        section?.sectionTitle.trim() === SectionTitle.trim() &&
        section?.groupId === groupId &&
        section?.isRequired === true
    );
    const hasFilledControl = requiredSection?.userControls?.some(
      (control) => control.value === "true"
    );
    filledControlArray.push(hasFilledControl);
    if (filledControlArray.includes(false)) {
      isValidate = true;
    }
    return isValidate;
  }
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className={`custom-accordion ${isRequiredAccordion() ? "required_accordion" : ""
          }`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div>
            {!showComplete() ? (
              <></>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" rx="8" fill="#00CC6A" />
                <path
                  d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                  className="icons-color"
                />
              </svg>
            )}
            <p className="acc_bold">{SectionTitle}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="nms-accordion-boxes-wrapper">
          <div className="accordion-boxes">
            {inputValues?.map((item, index) => {
              if (!alreadyControlRendered.includes(index)) {
                alreadyControlRendered.push(index);

                if (item.inline === true) {
                  rowCount > 2 ? (rowCount = 0) : rowCount++;
                }
                if (item.isChildControl === true) {
                  return null;
                }
                if (item.type === 7) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="dose">
                          <CustomDropdown
                            label={item.label}
                            items={item.listItems}
                            selected={item.value}
                            isEdit={stageStatus}
                          />
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 5) {
                  if (
                    item.isChildControl === true ||
                    item.parentUserControlId === null
                  ) {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div
                            className="switch-content-reports"
                            onClick={(event) =>
                              handleFormChange(
                                index,
                                {
                                  target: { checked: !JSON.parse(item?.value) },
                                },
                                inputTypes[item?.type],
                                item?.label,
                                item?.value
                              )
                            }
                          >
                            <CustomLabel name={item.label} />
                            <div>
                              <AntSwitch
                                disabled={stageStatus}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputTypes[item.type],
                                    item.label,
                                    item.value
                                  )
                                }
                                checked={
                                  item.value === ""
                                    ? "false"
                                    : JSON.parse(item.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="sub-quest">
                            {inputValues?.map((subItem, index) => {
                              if (
                                subItem?.isChildControl === true &&
                                subItem?.parentUserControlId !== null &&
                                subItem?.parentUserControlId ===
                                item?.userControlId
                              ) {
                                alreadyControlRendered.push(index);
                                return (
                                  <SubItem
                                    key={index}
                                    item={item}
                                    subItem={subItem}
                                    stageStatus={stageStatus}
                                    handleFormChange={handleFormChange}
                                    index={index}
                                    addedTexts={addedTexts}
                                    isCompleted={isCompleted}
                                    addText={addText}
                                  />
                                );
                              }
                            })}
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </React.Fragment>
                    );
                  }
                }
                if (item.type === 3) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div key={index} className="template_control">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="template_input_inner text_input_field"
                              label={item.label}
                              defaultValue={dayjs(
                                item.value === null ? "2022-04-17" : item.value
                              )}
                              disabled={stageStatus}
                              onChange={(newValue) =>
                                handleFormChange(
                                  index,
                                  newValue,
                                  inputTypes[item.type],
                                  item.label,
                                  item.value
                                )
                              }
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 0) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div key={index} className="template_control">
                          <TextField
                            className="template_input_inner text_input_field"
                            error={
                              item.required
                                ? isCompleted === true
                                  ? item.value === ""
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            required
                            id="outlined-required"
                            disabled={stageStatus}
                            label={item.label}
                            defaultValue={item.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item.type],
                                item.label,
                                item.value
                              )
                            }
                          />
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 9) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="radio-text-box">
                          <div className="switch-content-reports">
                            <CustomLabel name={item.label} />
                            <div>
                              <AntSwitch
                                key={index}
                                disabled={stageStatus}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(event) => addText(index)}
                                checked={addedTexts.includes(index)}
                              />
                            </div>
                          </div>
                          {addedTexts.includes(index) ? (
                            <div className="template_control">
                              <TextField
                                className="template_input text_input_field"
                                error={
                                  item.required
                                    ? isCompleted === true
                                      ? item.value === ""
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                required
                                id="outlined-required"
                                disabled={stageStatus}
                                label="Required"
                                defaultValue={item.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputTypes[item.type],
                                    item.label,
                                    item.value
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item?.type === 12) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item?.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div key={index} className="template_control">
                          <p className="label-textfield">{item?.label}</p>
                          <TextField
                            error={
                              item?.required
                                ? isCompleted === true
                                  ? item?.value === ""
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            multiline
                            rows={4}
                            required
                            id="outlined-required"
                            disabled={stageStatus}
                            label="Comments"
                            defaultValue={item?.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item?.type],
                                item.label,
                                item.value
                              )
                            }
                            className="textfield"
                            inputProps={{ maxLength: 5000 }}
                          />
                        </div>
                        {isExceeded && (
                          <p className="max-word-limit">
                            *Your character limit has been reached
                          </p>
                        )}
                      </div>

                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
              }
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
