import React, { useEffect, useState } from "react";
import "./../../../../../assets/css/Dashboard/ServicesKanban.scss";
import { useNavigate, useRevalidator } from "react-router-dom";
import { ReactComponent as NHSIcon } from "../../ServicesIcons/nhs.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import commonData from "../../../../../data/commonData";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Skeleton } from "@mui/material";
import { HiLockClosed } from "react-icons/hi2";
import {
  getUserLog,
  setCurrentPharmacyServices,
  setEligibilityStageScrollPosition,
  setInterventionStageScrollPosition,
  setOrbitClinicalServiceId,
  setScrollPosition,
  setSentToOrbit,
  updateCalendarEventByClinicalServiceId,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import {
  getStartDateForStages,
  getTotalAttemptsForStages,
  getTotalDaysLeft,
  getDueName,
  getAge,
  getTotalServicesDueCount,
  isDateBeforeMin,
} from "../../../../../data/getStartDateForStages";
import SmsStages from "../SmsStages";
import PatientTag from "../PatientTag";
import PatientTelephone from "../PatientTelephone";
import SentSmsText from "../SentSmsText";
import { isSmsSent } from "../../../../../data/getKanbanAndListViewData";
import { TbCalendarTime } from "react-icons/tb";
import ServiceMedication from "../ServiceMedication";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";
import OrbitStages from "../OrbitStages";
import ServicesCheckbox from "../ServicesCheckbox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ServicesKanbanAccordian({
  service,
  currentIndex,
  stage,
  smsQueue,
  reSmsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  setCurrentSmsQueue,
  setCurrentReSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  checksCount,
  setChecksCount,
  servicesUpdatedData,
}) {
  const { pharmacyServiceState, appState } = useTitanApp();
  const { darkMode, features } = appState || {};
  const { isPharmacyServiceLoading, pharmacyServices } = pharmacyServiceState;
  const { serviceIcons } = commonData || {};
  const navigate = useNavigate();

  const groupedClinicalServices = service?.clinicalServices.reduce(
    (acc, clinicalService) => {
      const patientId = clinicalService.patientId;
      acc[patientId] = acc[patientId] || [];
      acc[patientId].push(clinicalService);
      return acc;
    },
    {}
  );

  const handleClick = async (clinicalServiceId, isLocked) => {
    if (isLocked) {
      setOrbitClinicalServiceId(clinicalServiceId);
      setSentToOrbit(true);
    } else {
      getUserLog(clinicalServiceId).then((data) => {
        if (!data?.isActive) {
          navigate(`/nmsintervention/${clinicalServiceId}`, {
            state: {
              serviceType: "Kanban",
            },
          });
        }
      });
    }
  };

  useEffect(() => {
    function toggleScrollbar() {
      const columns = document.querySelectorAll(".stage");
      columns.forEach((column) => {
        if (column.scrollHeight > column.clientHeight) {
          column.classList.add("with-scrollbar");
        } else {
          column.classList.remove("with-scrollbar");
        }
      });
    }
    toggleScrollbar();
    window.addEventListener("resize", toggleScrollbar);
  }, []);

  const [newestDateTime, setNewestDateTime] = useState("");

  const handleChange = (newDate, index) => {
    if (newestDateTime !== "") {
      const firstDateTime = moment(newDate).format("HH:mm:ss");
      const updatedSecondDate = moment(newestDateTime)
        .set({
          hour: moment(firstDateTime, "HH:mm:ss").hour(),
          minute: moment(firstDateTime, "HH:mm:ss").minute(),
          second: moment(firstDateTime, "HH:mm:ss").second(),
        })
        .toDate();
      setNewestDateTime(updatedSecondDate);
    } else {
      setNewestDateTime(newDate);
    }
  };

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  const handleCalendarClose = (clinicalServiceId) => {
    if (newestDateTime !== "") {
      const parsedDate = moment(
        newestDateTime,
        "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)"
      );
      const formattedDateTime = parsedDate.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      const formattedDateTimeToReplace = parsedDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      let stageNo;
      switch (stage?.stageType) {
        case 1:
          stageNo = 0;
          break;
        case 2:
          stageNo = 1;
          break;
        case 3:
          stageNo = 2;
          break;
        case 4:
          stageNo = 3;
          break;
      }

      if (formattedDateTime !== "") {
        updateCalendarEventByClinicalServiceId({
          formattedDateTime,
          clinicalServiceId,
          stageNo,
        }).then((data) => {
          try {
            const newData = pharmacyServices?.map((stage) => ({
              ...stage,
              sortedServices: stage?.sortedServices?.map((service) => ({
                ...service,
                clinicalServices: service?.clinicalServices.map(
                  (clinicalService) => ({
                    ...clinicalService,
                    stages: clinicalService?.stages.map((stage) => ({
                      ...stage,
                    })),
                  })
                ),
              })),
            }));

            newData?.forEach((stage) => {
              stage?.sortedServices?.forEach((serviceGroup) => {
                serviceGroup?.clinicalServices?.forEach((clinicalService) => {
                  if (clinicalService._id === clinicalServiceId) {
                    let stageFound = false;
                    clinicalService.stages = clinicalService?.stages?.map(
                      (stage) => {
                        if (
                          !stageFound &&
                          stage.clinicalServiceStageStatus === 0
                        ) {
                          stageFound = true;
                          return {
                            ...stage,
                            startDate: formattedDateTimeToReplace,
                          };
                        }
                        return stage;
                      }
                    );
                  }
                });
              });
            });
            showToast("Record has been rescheduled successfully!");
            setCurrentPharmacyServices(newData);
            const stagesWrapper = document.querySelector(".stages-wrapper");
            if (stagesWrapper) {
              setScrollPosition(stagesWrapper.scrollLeft);
            }
            const stageEligible = document.getElementById("stage_Eligible");
            if (stageEligible) {
              setEligibilityStageScrollPosition(stageEligible.scrollTop);
            }
            const stageIntervention =
              document.getElementById("stage_Intervention");
            if (stageIntervention) {
              setInterventionStageScrollPosition(stageIntervention.scrollTop);
            }
          } catch (error) {}
        });
      } else {
        showError("Something went wrong!");
      }
    }
  };

  return (
    <Accordion key={currentIndex} className="dueDate_accordion" defaultExpanded>
      <AccordionSummary
        key={currentIndex}
        expandIcon={
          <ExpandMoreIcon fontSize="14" className="expandIcon_dueDate" />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <p className="service_title service_duedate_title">
            {isPharmacyServiceLoading ? (
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.900" : "",
                }}
                height={15}
                animation="wave"
              />
            ) : (
              `${getDueName(service?.dueType)} ${getTotalServicesDueCount(
                service,
                stage?.stageName,
                service?.dueType
              )}`
            )}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails key={currentIndex} className="data-box-container">
        {Object.values(groupedClinicalServices).map((group, groupIndex) =>
          group.length > 1 ? (
            <div className="stacked-cards-container">
              {group.map((service, index) => {
                const hasConsent = group.some((item) => item?.isConsent);
                const serviceIcon = serviceIcons[service.serviceName];
                const date = getStartDateForStages(service, stage?.stageName);
                const parsedDate = moment(date).toDate();
                const totalAttempts = getTotalAttemptsForStages(
                  service,
                  stage?.stageName
                );

                return (
                  <>
                    <div className="check_service" key={index}>
                      <div
                        key={index}
                        className="services_list"
                        style={{ position: "relative" }}
                        onClick={() =>
                          handleClick(service?._id, service?.sendToOrbit)
                        }
                      >
                        {isPharmacyServiceLoading ? (
                          <div className="service_item_skeleton">
                            <Skeleton
                              sx={{
                                bgcolor: darkMode ? "grey.900" : "",
                              }}
                              variant="rounded"
                              height={95}
                            />
                          </div>
                        ) : (
                          <div
                            className="service_item stk-card"
                            style={{
                              width:
                                pharmacyServices?.length === 1
                                  ? "100%"
                                  : pharmacyServices?.length === 2
                                  ? "45%"
                                  : pharmacyServices?.length > 2
                                  ? "430px"
                                  : "",
                              transform: `translateY(${index * 9}px)`,
                              zIndex: group.length - index,
                            }}
                          >
                            <div className="name-box">
                              <div className="checkbox_name">
                                {/* {stage?.stageType === 1 &&
                                !orbitQueue?.length > 0 ? (
                                  <SmsStages
                                    service={service}
                                    view="kanban"
                                    smsQueue={smsQueue}
                                    setCurrentSmsQueue={setCurrentSmsQueue}
                                    interventionQueue={interventionQueue}
                                    setCurrentInterventionQueue={
                                      setCurrentInterventionQueue
                                    }
                                  />
                                ) : (
                                  <></>
                                )}
                                {ifSendToOrbit &&
                                stage?.stageType === 3 &&
                                !service?.sendToOrbit &&
                                !smsQueue?.length > 0 ? (
                                  <OrbitStages
                                    service={service}
                                    view="kanban"
                                    orbitQueue={orbitQueue}
                                    setCurrentOrbitQueue={setCurrentOrbitQueue}
                                  />
                                ) : (
                                  <></>
                                )} */}
                                <ServicesCheckbox
                                  view="kanban"
                                  service={service}
                                  stageType={stage.stageType}
                                  smsQueue={smsQueue}
                                  reSmsQueue={reSmsQueue}
                                  interventionQueue={interventionQueue}
                                  declineQueue={declineQueue}
                                  rescheduleQueue={rescheduleQueue}
                                  pdsQueue={pdsQueue}
                                  orbitQueue={orbitQueue}
                                  setCurrentSmsQueue={setCurrentSmsQueue}
                                  setCurrentReSmsQueue={setCurrentReSmsQueue}
                                  setCurrentInterventionQueue={
                                    setCurrentInterventionQueue
                                  }
                                  setCurrentDeclineQueue={
                                    setCurrentDeclineQueue
                                  }
                                  setCurrentRescheduleQueue={
                                    setCurrentRescheduleQueue
                                  }
                                  setCurrentPdsQueue={setCurrentPdsQueue}
                                  setCurrentOrbitQueue={setCurrentOrbitQueue}
                                  checksCount={checksCount}
                                  setChecksCount={setChecksCount}
                                />
                                <p className="services_kanban-serviceName">
                                  {service?.patientName}
                                  {service?.dob !== null &&
                                  isDateBeforeMin(service?.dob)
                                    ? ` (${getAge(service?.dob)})`
                                    : ""}
                                </p>
                              </div>
                              <div className="lock_days">
                                {service?.sendToOrbit && (
                                  <span>
                                    <HiLockClosed size={15} />
                                  </span>
                                )}
                                <span className="services_listview-date">
                                  {getTotalDaysLeft(service, stage?.stageName)}
                                </span>
                              </div>
                            </div>
                            <div className="service_tags_list">
                              {service?.patientTags?.map((tag, index) => (
                                <PatientTag
                                  key={index}
                                  tag={tag}
                                  index={index}
                                />
                              ))}
                            </div>
                            {service?.telephoneNumber === null ||
                            service?.telephoneNumber === "" ? (
                              <PatientTelephone
                                id={service?._id}
                                index={index}
                              />
                            ) : (
                              <></>
                            )}
                            {hasConsent && stage?.stageType === 1 ? (
                              <div className="patient_consented_text">
                                <p>
                                  {moment(service?.clinicalServiceDate).format(
                                    "DD/MM"
                                  )}{" "}
                                  Patient Consented
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="text">
                              <p className="attempts">
                                {totalAttempts !== 0 &&
                                  totalAttempts + " attempts"}
                              </p>
                              {stage?.stageType === 2 &&
                              isSmsSent(service?._id) ? (
                                <SentSmsText id={service._id} />
                              ) : (
                                <></>
                              )}
                            </div>
                            {service?.serviceMedications?.map(
                              (medications, index) => (
                                <ServiceMedication
                                  key={index}
                                  medications={medications}
                                />
                              )
                            )}
                            <div className="services_bottom">
                              <div
                                className="services_listview-service date-time-select"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="services_iconboxService">
                                  <TbCalendarTime
                                    size={15}
                                    color={darkMode ? "#fff" : "#000"}
                                  />
                                  <DatePicker
                                    className="widgetDatePicker"
                                    onChange={(newDate) =>
                                      handleChange(newDate, index)
                                    }
                                    showTimeSelect
                                    selected={parsedDate}
                                    timeIntervals={15}
                                    dateFormat="dd.MM HH:mm"
                                    onCalendarClose={() =>
                                      handleCalendarClose(service?._id)
                                    }
                                  />
                                </div>
                                {service?.serviceType == 1 ? (
                                  <NHSIcon />
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="services_listview-service">
                                <div className="services_iconboxService">
                                  <div className="services_icon">
                                    {serviceIcon}
                                  </div>
                                  <span className="service_service_name">
                                    {service?.serviceName}
                                  </span>
                                </div>
                                {service?.serviceType == 1 ? (
                                  <NHSIcon />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="bottom-stacked"
                        style={{
                          width:
                            pharmacyServices?.length === 1
                              ? "100%"
                              : pharmacyServices?.length === 2
                              ? "45%"
                              : pharmacyServices?.length > 2
                              ? "430px"
                              : "",
                        }}
                      ></div>
                      <div
                        className="bottom-stacked"
                        style={{
                          width:
                            pharmacyServices?.length === 1
                              ? "100%"
                              : pharmacyServices?.length === 2
                              ? "45%"
                              : pharmacyServices?.length > 2
                              ? "430px"
                              : "",
                        }}
                      ></div>
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            group.map((service, index) => {
              const serviceIcon = serviceIcons[service.serviceName];
              const date = getStartDateForStages(service, stage?.stageName);
              const parsedDate = moment(date).toDate();
              const totalAttempts = getTotalAttemptsForStages(
                service,
                stage?.stageName
              );

              const updated = servicesUpdatedData?.includes(service?._id);

              return (
                <div className="check_service" key={index}>
                  <div
                    key={index}
                    className="services_list"
                    onClick={() =>
                      handleClick(service?._id, service?.sendToOrbit)
                    }
                  >
                    {isPharmacyServiceLoading ? (
                      <div className="service_item_skeleton">
                        <Skeleton
                          sx={{
                            bgcolor: darkMode ? "grey.900" : "",
                          }}
                          variant="rounded"
                          height={95}
                        />
                      </div>
                    ) : (
                      <div
                        className={`service_item ${
                          updated ? "kanban-success-border" : ""
                        }`}
                        style={{
                          width:
                            pharmacyServices?.length === 1
                              ? "100%"
                              : pharmacyServices?.length === 2
                              ? "45%"
                              : pharmacyServices?.length > 2
                              ? "430px"
                              : "",
                        }}
                      >
                        <div className="name-box">
                          <div className="checkbox_name">
                            {/* {stage?.stageType === 1 &&
                            orbitQueue?.length === 0 ? (
                              <SmsStages
                                service={service}
                                view="kanban"
                                smsQueue={smsQueue}
                                setCurrentSmsQueue={setCurrentSmsQueue}
                                interventionQueue={interventionQueue}
                                setCurrentInterventionQueue={
                                  setCurrentInterventionQueue
                                }
                              />
                            ) : (
                              <></>
                            )}
                            {ifSendToOrbit &&
                            stage?.stageType === 3 &&
                            !service?.sendToOrbit &&
                            smsQueue?.length === 0 ? (
                              <OrbitStages
                                service={service}
                                view="kanban"
                                orbitQueue={orbitQueue}
                                setCurrentOrbitQueue={setCurrentOrbitQueue}
                              />
                            ) : (
                              <></>
                            )} */}
                            <ServicesCheckbox
                              view="kanban"
                              service={service}
                              stageType={stage.stageType}
                              addedService={service}
                              smsQueue={smsQueue}
                              reSmsQueue={reSmsQueue}
                              interventionQueue={interventionQueue}
                              declineQueue={declineQueue}
                              rescheduleQueue={rescheduleQueue}
                              pdsQueue={pdsQueue}
                              orbitQueue={orbitQueue}
                              setCurrentSmsQueue={setCurrentSmsQueue}
                              setCurrentReSmsQueue={setCurrentReSmsQueue}
                              setCurrentInterventionQueue={
                                setCurrentInterventionQueue
                              }
                              setCurrentDeclineQueue={setCurrentDeclineQueue}
                              setCurrentRescheduleQueue={
                                setCurrentRescheduleQueue
                              }
                              setCurrentPdsQueue={setCurrentPdsQueue}
                              setCurrentOrbitQueue={setCurrentOrbitQueue}
                              checksCount={checksCount}
                              setChecksCount={setChecksCount}
                            />
                            <p className="services_kanban-serviceName">
                              {service?.patientName}
                              {service?.dob !== null &&
                              isDateBeforeMin(service?.dob)
                                ? ` (${getAge(service?.dob)})`
                                : ""}
                            </p>
                          </div>
                          <div className="lock_days">
                            {service?.sendToOrbit && (
                              <span>
                                <HiLockClosed size={15} />
                              </span>
                            )}
                            <span className="services_listview-date">
                              {getTotalDaysLeft(service, stage?.stageName)}
                            </span>
                          </div>
                        </div>
                        <div className="service_tags_list">
                          {service?.patientTags?.map((tag, index) => (
                            <PatientTag key={index} tag={tag} index={index} />
                          ))}
                        </div>
                        {service?.telephoneNumber === null ||
                        service?.telephoneNumber === "" ? (
                          <PatientTelephone id={service?._id} index={index} />
                        ) : (
                          <></>
                        )}
                        {service?.isConsent && stage?.stageType === 1 ? (
                          <div className="patient_consented_text">
                            <p>
                              {moment(service.clinicalServiceDate).format(
                                "DD/MM"
                              )}{" "}
                              Patient Consented
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="text">
                          <p className="attempts">
                            {totalAttempts !== 0 && totalAttempts + " attempts"}
                          </p>
                          {stage?.stageType === 2 && isSmsSent(service?._id) ? (
                            <SentSmsText id={service._id} />
                          ) : (
                            <></>
                          )}
                        </div>
                        {service?.serviceMedications?.map(
                          (medications, index) => (
                            <ServiceMedication
                              key={index}
                              medications={medications}
                            />
                          )
                        )}
                        <div className="services_bottom">
                          <div
                            className="services_listview-service date-time-select"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="services_iconboxService">
                              <TbCalendarTime
                                size={15}
                                color={darkMode ? "#fff" : "#000"}
                              />
                              <DatePicker
                                className="widgetDatePicker"
                                onChange={(newDate) =>
                                  handleChange(newDate, index)
                                }
                                showTimeSelect
                                selected={parsedDate}
                                timeIntervals={15}
                                dateFormat="dd.MM HH:mm"
                                onCalendarClose={() =>
                                  handleCalendarClose(service?._id)
                                }
                              />
                            </div>
                            {service?.serviceType == 1 ? <NHSIcon /> : <></>}
                          </div>
                          <div className="services_listview-service">
                            <div className="services_iconboxService">
                              <div className="services_icon">{serviceIcon}</div>
                              <span className="service_service_name">
                                {service?.serviceName}
                              </span>
                            </div>
                            {service?.serviceType == 1 ? <NHSIcon /> : <></>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )
        )}
      </AccordionDetails>
    </Accordion>
  );
}
export default React.memo(ServicesKanbanAccordian);
