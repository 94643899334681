import { dispatch, getDataFromStore } from "../store/store";
import {
  getPrescriptionIncomingIdsData,
  getPrescriptionByIncomingIdData,
  getMedicationData,
  getPostMedicationData,
  getAdditionalEndorsementData,
  setPatientClinicalChecksData,
  getPatientImageData,
  getPrescriptionItemChecksData,
  getPrescriptionChecksData,
  setIsCheckNowModel,
  setIsNmsPrescriptionLoading,
  setIsItemchecks,
  setIsSeverityCount,
  setIsMandatory,
  setIsTheraphy,
  setIsEndorse,
  setIsDispenseArray,
  setClinicalChecksNotes,
  setIsItemReturnDose,
  setIsItemReturnQuantity,
  setIsReturnQuantity,
  setIsReturnDose,
  setIsReturnDispense,
  setIsReturnStartDate,
  setIsReturnStopDate,
  setIsReturnMarking,
  setIsReturnTherapy,
  setIsReturnPreparation,
  setIsReturnArchive,
  setIsReturnColor,
  setIsReturnReport,
  setIsDispense,
  setIsEditable,
  setIsButtonclass,
  setIsNotesModel,
  setIsClinicalHeaderNotesModel,
  setMedicationTimeModel,
  setIsMedicineWarning,
  setIsPatientImage,
  setIsMedicationId,
  setIsParkedData,
} from "../features/clinicalChecksSlice/clinicalChecksSlice";
import { logError } from "../services/logService";

const reducerName = "clinicalChecksService";

// #region State Setters

export const setSeverityCount = (severityCount) => {
  dispatch(setIsSeverityCount(severityCount));
};
export const setCurrentIsNmsPrescriptionLoading = (value) => {
  dispatch(setIsNmsPrescriptionLoading(value));
};
export const setDispenseArray = (dispenseArray) => {
  dispatch(setIsDispenseArray(dispenseArray));
};
export const setMandatory = (isMandatory) => {
  dispatch(setIsMandatory(isMandatory));
};
export const setTheraphy = (isTheraphy) => {
  dispatch(setIsTheraphy(isTheraphy));
};
export const setItemReturnDose = (itemDose) => {
  dispatch(setIsItemReturnDose(itemDose));
};
export const setItemReturnQuantity = (itemQuantity) => {
  dispatch(setIsItemReturnQuantity(itemQuantity));
};
export const setReturnQuantity = (returnQuantity) => {
  dispatch(setIsReturnQuantity(returnQuantity));
};
export const setReturnDose = (returnDose) => {
  dispatch(setIsReturnDose(returnDose));
};
export const setReturnDispense = (returnDispense) => {
  dispatch(setIsReturnDispense(returnDispense));
};
export const setDispense = (isDispense) => {
  dispatch(setIsDispense(isDispense));
};
export const setEditable = (isEditable) => {
  dispatch(setIsEditable(isEditable));
};
export const setButtonclass = (isButtonClass) => {
  dispatch(setIsButtonclass(isButtonClass));
};
export const setNotes = (notes) => {
  dispatch(setIsNotesModel(notes));
};
export const setChecksHeaderNotes = (notes) => {
  dispatch(setIsClinicalHeaderNotesModel(notes));
};
export const setMedicationTime = (medicationTime) => {
  dispatch(setMedicationTimeModel(medicationTime));
};
export const setMedicineWarning = (medicineWarnings) => {
  dispatch(setIsMedicineWarning(medicineWarnings));
};
export const setReturnStartDate = (returnStartDate) => {
  dispatch(setIsReturnStartDate(returnStartDate));
};
export const setReturnStopDate = (returnStopDate) => {
  dispatch(setIsReturnStopDate(returnStopDate));
};
export const setReturnMarking = (returnMarketing) => {
  dispatch(setIsReturnMarking(returnMarketing));
};
export const setReturnArchive = (returnArchive) => {
  dispatch(setIsReturnArchive(returnArchive));
};
export const setReturnColor = (returnColor) => {
  dispatch(setIsReturnColor(returnColor));
};
export const setReturnReport = (returnReport) => {
  dispatch(setIsReturnReport(returnReport));
};
export const setReturnTherapy = (returnTherapy) => {
  dispatch(setIsReturnTherapy(returnTherapy));
};
export const setReturnPreparation = (returnPreparation) => {
  dispatch(setIsReturnPreparation(returnPreparation));
};
export const setEndorse = (isTheraphy) => {
  dispatch(setIsEndorse(isTheraphy));
};
export const setItemChecks = (itemChecks) => {
  dispatch(setIsItemchecks(itemChecks));
};
export const setCurrentClinicalChecksNotes = (itemChecks) => {
  dispatch(setClinicalChecksNotes(itemChecks));
};
export const setCheckNowModel = (checkModel) => {
  dispatch(setIsCheckNowModel(checkModel));
};
export const setPatientImage = (patientImage) => {
  dispatch(setIsPatientImage(patientImage));
};
export const setMedicationId = (isMedication) => {
  dispatch(setIsMedicationId(isMedication));
};
export const setIsParked = (parked) => {
  dispatch(setIsParkedData(parked));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers

export const getPrescriptionIncomingIds = async (param) => {
  let result = null;
  try {
    result = await dispatch(getPrescriptionIncomingIdsData(param)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPrescriptionByIncomingId = async (param) => {
  let result = null;
  try {
    result = await dispatch(getPrescriptionByIncomingIdData(param)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getPatientMedication = async (params) => {
  let result = null;
  try {
    result = await dispatch(getMedicationData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPostMedication = async (params) => {
  let result = null;
  try {
    result = await dispatch(getPostMedicationData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getAdditionalEndorsement = async (params) => {
  let result = null;
  try {
    result = await dispatch(getAdditionalEndorsementData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPatientImage = async (params) => {
  let result = null;
  try {
    result = await dispatch(getPatientImageData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const setPatientClinicalChecks = async (params) => {
  let result = null;
  try {
    result = await dispatch(setPatientClinicalChecksData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getPrescriptionItemCheck = async (params) => {
  let result = null;
  try {
    result = await dispatch(getPrescriptionItemChecksData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPrescriptionChecks = async (params) => {
  let result = null;
  try {
    result = await dispatch(getPrescriptionChecksData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
