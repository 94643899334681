import { generateObjectId } from "../../utils/generateObjectId";
class FollowUp {
  constructor(
    StartDay,
    EndDay,
    title,
    Description,
    pharmacyId,
    ClinicalpatientId,
    clinicalServiceId,
    stageNo,
    serviceID
  ) {
    this._id = generateObjectId();
    this.title = title;

    this.description = Description;
    this.start = StartDay;
    this.end = EndDay;
    this.icon = 0;
    this.calendarEventStatus = 0;
    this.entityId = clinicalServiceId;
    this.entityType = 1;
    this.serviceID = serviceID;
    this.clinicalServiceID = clinicalServiceId;
    this.pharmacyID = pharmacyId;
    this.patientID = ClinicalpatientId;
    this.stageType = stageNo;
  }
}

export default FollowUp;
