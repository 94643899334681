import React, { useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./../../assets/css/EndorsementModal.scss";
import { v4 as uuidv4 } from "uuid";
import CustomLabel from "../controls/CustomLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import { PrescriptionItemEndorseType } from "../../enum/PrescriptionItemEndorseType";
import useTitanApp from "../../hooks/useTitanApp";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import {
  setCheckNowModel,
  setEndorse,
} from "../../dispatchers/clinicalChecksDispatchers";
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props || {};
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,

  boxShadow: 24,
  p: 4,
};

export default function EndorsementModal({ PrescriptionItemId }) {
  const { clincicalChecksState } = useTitanApp();
  const {
    isEndorse,
    additionalEndorsement,
    checkNowModel,
    postMedicationId,
    itemReturnDose,
    itemReturnQuantity,
    returnDispense,
    isReturnDispense,
  } = clincicalChecksState || {};

  const [EndorsementModel, setEndorsementModel] = useState([]);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  function UpdateEndorseModel(endorseModel) {
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      PrescriptionItemId,
      "",
      itemReturnQuantity,
      itemReturnDose,
      false,
      postMedicationId,
      false,
      0,
      isReturnDispense,
      isReturnDispense === true ? returnDispense : "",
      false,
      "",
      false,
      "",
      endorseModel,
      false
    );
    setCheckNowModel(newCheckNowModel);
    setEndorse(false);
  }
  function handleFormChange(index, event, code) {
    if (event.target.checked === true) {
      const newObj = {
        prescriptionItemEndorseAdditionalId: uuidv4(),
        prescriptionItemId: PrescriptionItemId,
        additionEndorseType: code,
        value: 0,
        quantity: 0,
        vpId: 0,
        expiryDate: "",
        mhraNumber: "",
        batchNumber: "",
        details: "",
        isDeleted: true,
        price: 0,
        packSize: 0,
        supplier: "",
        pharmacistInitials: "",
        brand: "",
        dispenseDate: "",
        packagedDoses: 0,
      };
      const updatedEndorsementModel = [...EndorsementModel, newObj];
      setEndorsementModel(updatedEndorsementModel);
    } else {
      const updatedEndorsementModel = EndorsementModel.filter(
        (item) => item.additionEndorseType !== code
      );
      setEndorsementModel(updatedEndorsementModel);
    }
  }
  const handleDispensedDate = (event, endorseType) => {
    const newDispensedDate = event?.target?.value;
    const updatedEndosreModel = [...EndorsementModel];
    const indexToUpdate = updatedEndosreModel.findIndex(
      (item) => item.additionEndorseType === endorseType
    );

    if (indexToUpdate !== -1) {
      updatedEndosreModel[indexToUpdate].dispenseDate = newDispensedDate;

      setEndorsementModel(updatedEndosreModel);
    }
  };
  const handlePharmacistInitials = (event, endorseType) => {
    const newPharmacistInitials = event?.target?.value;
    const updatedEndosreModel = [...EndorsementModel];
    const indexToUpdate = updatedEndosreModel.findIndex(
      (item) => item.additionEndorseType === endorseType
    );

    if (indexToUpdate !== -1) {
      updatedEndosreModel[indexToUpdate].pharmacistInitials =
        newPharmacistInitials;

      setEndorsementModel(updatedEndosreModel);
    }
  };

  function isSwitched(code) {
    const isPresent = EndorsementModel.find(
      (item) => item.additionEndorseType === code
    );
    return isPresent ? true : false;
  }
  function SubmitEndorsement() {
    UpdateEndorseModel(EndorsementModel);
  }
  function onClose() {
    setEndorse(false);
  }
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isEndorse}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={isEndorse}>
          <Box sx={style} className="endorsement-box">
            <div className="additional-div">
              <div className="endorse-heading">
                <h5>Prescriber Endorsements</h5>
              </div>

              <div className="prescriber-content">
                {additionalEndorsement?.allowedAdditionals?.map((item) => {
                  switch (item) {
                    case 13:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                      return (
                        <div className="endorse-switch">
                          <CustomLabel
                            name={PrescriptionItemEndorseType[item]}
                          />
                          <div>
                            <AntSwitch
                              disabled={true}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </div>
                        </div>
                      );
                  }
                })}
              </div>
              <div className="endorse-heading">
                <h5>Dispenser Endorsements</h5>
              </div>

              <div className="dispenser-content">
                {additionalEndorsement?.allowedAdditionals?.map(
                  (item, index) => {
                    switch (item) {
                      case 9:
                      case 10:
                        return (
                          <>
                            <div className="endorse-switch">
                              <CustomLabel
                                name={PrescriptionItemEndorseType[item]}
                              />
                              <div>
                                <AntSwitch
                                  //   key={index}
                                  disabled={false}
                                  inputProps={{ "aria-label": "ant design" }}
                                  onChange={(event) =>
                                    handleFormChange(index, event, item)
                                  }
                                  checked={isSwitched(item)}
                                />
                              </div>
                            </div>
                            {isSwitched(item) ? (
                              <div className="endorse-controls">
                                <div className="endorse-item">
                                  <CustomLabel name="Pharmacist initialts" />
                                  <input
                                    type="text"
                                    onChange={(event) =>
                                      handlePharmacistInitials(event, item)
                                    }
                                  />
                                </div>
                                <div className="endorse-item">
                                  <CustomLabel name="Dispensed date" />
                                  <input
                                    type="date"
                                    onChange={(event) =>
                                      handleDispensedDate(event, item)
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                        break;
                    }
                  }
                )}
              </div>
            </div>
            <div className="buttons-bar-en">
              <button className="form-buttons">Cancel</button>
              <button className="form-buttons" onClick={SubmitEndorsement}>
                Submit
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
