import prescriptionItems from "./prescriptionItems";
import prescriptionCheckActions from "./prescriptionCheckActions";

class setCheckNow {
  constructor(
    response,
    PrescriptionItemCheckActionsObj,
    PrescriptionItemId,
    PrescriptionCheckActions,
    qty,
    dose,
    isUpdate,
    patientMedicationId,
    isActionUpdate,
    ErdType,
    ItemCrossedOut,
    NotDispensedReasonCode,
    isNotes,
    Notesmodel,
    isPrescriptionNotes,
    PrescriptionNotes,
    EndorseModel,
    isParked
  ) {
    this.prescriptionId = response.prescriptionId;
    this.prescriptionIncomingId = response.prescriptionIncomingId;
    this.organisationId = response.organisationId;
    this.patientId = response.patientId;
    this.prescriptionDate = response.prescriptionDate;
    this.prescriptionType = response.prescriptionType;
    this.prescriptionSourceType = response.prescriptionSourceType;
    this.isDeleted = response.isDeleted;
    this.activeStatusType = isParked ? 2 : 3;
    this.incomingStatusType = response.incomingStatusType;
    this.clinicalCheckDate = new Date();
    this.numberofItems = response.numberofItems;
    this.isHandWritten = response.pisHandWrittenrescriptionId;
    this.gpnhsNumber = response.gpnhsNumber;
    this.externalSourceBarcode = response.externalSourceBarcode;
    this.prescriberId = response.prescriberId;
    this.prescriberName = response.prescriberName;
    this.isEmergencyPrescription = response.isEmergencyPrescription;
    this.emergencySupplyReason = response.emergencySupplyReason;
    this.requestedBy = response.requestedBy;
    this.bucketType = response.bucketType == "" ? 0 : response.bucketType;
    this.referenceNumber = response.referenceNumber;
    this.AccountNumber = response.AccountNumber;

    this.prescriptionCheckActions =
      PrescriptionCheckActions === ""
        ? response.prescriptionCheckActions
        : new prescriptionCheckActions(PrescriptionCheckActions).obj;

    this.prescriptionItems = new prescriptionItems(
      response.prescriptionItems,

      PrescriptionItemCheckActionsObj,
      PrescriptionItemId,
      qty,
      dose,
      isUpdate,
      patientMedicationId,
      isActionUpdate,
      ItemCrossedOut,
      NotDispensedReasonCode,
      isNotes,
      Notesmodel,
      EndorseModel
    ).obj;
    this.erdType = ErdType === 0 ? response.erdType : ErdType;

    this.audit = response.audit;
    this.exemption = response.exemption;
    this.doNotPark = response.doNotPark ? response.doNotPark : false; //not in res
    this.sendToParked = isParked ? true : response.sendToParked; //not in res
    this.spineApiRequest = response.spineApiRequest;

    this.notes =
      isPrescriptionNotes === true ? PrescriptionNotes : response.notes;
  }
}

export default setCheckNow;
