import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./../../assets/css/NotesControl.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ImWarning } from "react-icons/im";
import { BsPinAngleFill } from "react-icons/bs";
import PrescriptionNotes from "../../Model/ClinicalCheckNotes/PrescriptionNotes";
import useTitanApp from "../../hooks/useTitanApp";
import setCheckNow from "../../Model/CheckNow/setCheckNow";

function AddedPrescriptionNotes({ item, handleAddedNotes }) {
  const {
    clincicalChecksState,
    setCheckNowModel,
    setCurrentClinicalChecksNotes,
  } = useTitanApp();

  const { clinicalChecksNotes, checkNowModel } = clincicalChecksState || {};

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openElem, setOpenElem] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [noteValue, setNotevalue] = useState(item.Note);
  const [isPinNote, setIsPinNote] = useState(false);
  const [isAwaitingQuery, setIsAwaitingQuery] = useState(false);
  const [isHandout, setIsHandout] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (ItemNoteId) => (event) => {
    setAnchorEl(event.currentTarget);

    setOpenElem(ItemNoteId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (noteId) => {
    const updatedNotes = clinicalChecksNotes.filter(
      (note) => note.PrescriptionIncomingNoteId !== openElem
    );
    setAnchorEl(null);
    setOpenElem(null);
    handleAddedNotes([...updatedNotes]);
  };

  const handlePin = async () => {
    setIsPinNote(!isPinNote);
    await setPrescriptionNotes("PinNote", isPinNote === false ? true : false);
  };
  const handleResolved = async () => {
    setIsAwaitingQuery(false);
    await setPrescriptionNotes("AwaitingQuery", false);
  };

  const handleAwaitingQuery = async () => {
    setIsAwaitingQuery(true);
    await setPrescriptionNotes("AwaitingQuery", true);
  };
  const handleEdit = async () => {
    setIsEdit(true);
    setAnchorEl(null);
  };
  const handleHangout = async () => {
    await setHandoutValue();
  };
  const setHandoutValue = async () => {
    setIsHandout(!isHandout);

    await setPrescriptionNotes(
      "ShowOnHandout",
      isHandout === false ? true : false
    );
  };

  const setPrescriptionNotes = async (TypeValue, isChecked) => {
    const updatedNotes = new PrescriptionNotes(
      clinicalChecksNotes,
      TypeValue,
      openElem,
      isChecked
    ).obj;
    setCurrentClinicalChecksNotes([...updatedNotes]);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      "",
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      true,
      clinicalChecksNotes,
      "",
      false
    );
    setCheckNowModel(newCheckNowModel);
    setAnchorEl(null);
    setOpenElem(null);
  };
  function ChangeEditNote(e) {
    setNotevalue(e?.target?.value);
  }
  function ConfirmEdit() {
    const updatedNotes = clinicalChecksNotes?.map((note) => {
      if (note.PrescriptionIncomingNoteId === openElem) {
        return { ...note, Note: noteValue };
      }
      return note;
    });
    handleAddedNotes([...updatedNotes]);
    CloseEdit();
  }
  function CloseEdit() {
    setNotevalue(item.Note);
    setIsEdit(false);
  }

  return (
    <div
      className={`note-item-container ${
        isPinNote
          ? "pinned-message"
          : isAwaitingQuery
          ? "awaiting-query-message"
          : ""
      }`}
    >
      {isAwaitingQuery ? (
        !isPinNote ? (
          <div className="note-item-message awaiting-query">
            <ImWarning />
            <p>Awaiting query</p>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {isPinNote ? (
        <div className="note-item-message pinned ">
          {isAwaitingQuery ? (
            <>
              <ImWarning />
              Awaiting query
            </>
          ) : (
            <></>
          )}
          <BsPinAngleFill className="in-icon" />
          Pinned
        </div>
      ) : (
        <></>
      )}
      <div className="note-item" key={item.PrescriptionIncomingNoteId}>
        <div className="note-item-wrapper">
          <div className="note-content"></div>
          <p className="title-note">{item.ProfessionalName}</p>
          {isEdit ? (
            <div className="edit-field">
              <input type="text" value={noteValue} onChange={ChangeEditNote} />
              <div className="note-confirm-close">
                <CheckIcon onClick={ConfirmEdit} />
                <CloseIcon onClick={CloseEdit} />
              </div>
            </div>
          ) : (
            <div className="note-value">
              <p>{noteValue}</p>
            </div>
          )}

          <div className="notes-footer">
            <p className="note-date">{item.CreatedOn.toLocaleString()}</p>
            <div>{isHandout ? <p>Handout</p> : <></>}</div>
          </div>
        </div>
        <div className="note-content">
          <MoreHorizIcon
            onClick={handleClick(item.PrescriptionIncomingNoteId)}
          />
        </div>
        <Menu
          key={item.PrescriptionIncomingNoteId}
          id="basic-menu"
          className="notes-menu clinicalchecks-notes-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handlePin}>
            {isPinNote ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            {isPinNote ? "Unpin" : "Pin"} comment
          </MenuItem>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem
            onClick={() => {
              handleDelete(item.PrescriptionIncomingNoteId);
            }}
            value={item.PrescriptionIncomingNoteId}
          >
            Delete
          </MenuItem>
          <hr />
          <MenuItem onClick={handleAwaitingQuery}>
            {isAwaitingQuery ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Mark as awaiting query
          </MenuItem>
          <MenuItem onClick={handleResolved}>Mark as resolved</MenuItem>
          <MenuItem onClick={handleHangout}>
            {isHandout ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Show on handout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default AddedPrescriptionNotes;
