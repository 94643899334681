import React, { useState } from "react";
import { FaPlay, FaArrowRight } from "react-icons/fa";
import "../../assets/clinicalcheck.css";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import nexmoClient from "nexmo-client";
import { phone } from "phone";
import {
  getVoiceJWT,
  setCurrentVoiceJWTToken,
  setCurrentIsCalling,
  saveTotalAttempts,
  updateTotalAttemptsByClinicalServiceId,
  setCurrentSkipNMSModal,
  setCurrentRescheduleComponent,
  setCurrentPatientDeclined,
  setOpenCallScreenDialog,
  setCurrentDeclineNMSModal,
  setCurrentDeclineComments,
  setCallStartTime,
  setCallEndTime,
  setIsFollowUpControl,
  setCurrentMultipleNumbersNMSModal,
  getPatientPhoneNumber,
} from "../../dispatchers/nmsDispatchers";
import {
  getPrescriptionByIncomingId,
  getPatientImage,
} from "../../dispatchers/clinicalChecksDispatchers";
import { stageTypes } from "../../enum/stageType";
import BreadCrumbNMS from "../../components/BreadCrumbs/BreadCrumb";
import useTitanApp from "../../hooks/useTitanApp";
import { showError } from "../../components/ToastedMessage/ToastedMessage";
import { formatPhone } from "../../utils/formatPhone";
import Dialog from "@mui/material/Dialog";
import { PiPhoneFill, PiPlayFill } from "react-icons/pi";
import CallingWidget from "./components/CallingWidget";
import InPersonConsultation from "./components/InPersonConsultation";
import { useStopwatch } from "react-timer-hook";
import { useStore } from "react-redux";
import { ActionTrackForServices } from "../../data/actionTrack";
import { formatPhoneNumber } from "../../data/formatPhone";
import { RxArrowLeft, RxCross2 } from "react-icons/rx";
import { updateUserCurrentStatus } from "../../dispatchers/appDispatchers";
import PatientDeclined from "./components/CallingWidget/PatientDeclined";
import FollowUpModal from "../../components/FollowUpModal/FollowUpModal";
import config from "./../../config";
import { telephoneType } from "../../enum/telephoneType";

function ServicesHeader({
  serviceType,
  currentIndex,
  stageName,
  stageNo,
  SkipClincialService,
  parkClinicalService,
  validateComplete,
  declineNms,
  submitNms,
  addAuditForSkipAndAttempt,
}) {
  const store = useStore();
  const { appState, nmsServiceState, auth } = useTitanApp();
  const { user } = auth || {};
  const { darkMode, currentUser, isOrbitUser, isOrbitSwitch } = appState || {};
  const {
    service,
    clinicalServiceId,
    skipNMSModal,
    declineNMSModal,
    multipleNumbersNMSModal,
    skipCounts,
    openCallScreenDialog,
    declineComments,
    isFollowUpControl,
    patientsTelephone,
  } = nmsServiceState || {};
  const userFullName = user?.name || "";
  const userEmail = user?.email || "";
  const inPersonConsultationTimer = useStopwatch();
  const callTimer = useStopwatch();
  let callTimeout;
  let warningTimeout;
  const { autoCutCallTimeout, autoCutCallShowWarningTimeout } = config;
  const called = localStorage.getItem(`${clinicalServiceId}_called`);
  // react state declaration
  const [nexmoCallStatus, setNexmoCallStatus] = useState("");
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState("");
  const [activeCall, setActiveCall] = useState(null);
  const [callStatus, setCallStatus] = useState(`Calling...`);
  const [warningCountdownStatus, setWarningCountdownStatus] =
    useState("1 minute left");
  const [showWarningCounter, setShowWarningCounter] = useState(false);
  const [openOptionsDialog, setOpenOptionsDialog] = React.useState(false);
  const [isConsultation, setIsConsultation] = useState(false);
  const [stopConsultation, setStopConsultation] = useState(false);
  const [stopConsultationMinutes, setStopConsultationMinutes] = useState(false);
  const [stopConsultationSeconds, setStopConsultationSeconds] = useState(false);
  const [openInPersonConsultationDialog, setOpenInPersonConsultationDialog] =
    React.useState(false);
  const [stoppedMinutes, setStoppedMinutes] = useState(0);
  const [stoppedSeconds, setStoppedSeconds] = useState(0);
  const [skipComments, setSkipComments] = useState("");
  const [isStart, setIsStart] = useState(true);
  const formattedConsultationMinutes = String(
    inPersonConsultationTimer.minutes
  ).padStart(2, "0");
  const formattedConsultationSeconds = String(
    inPersonConsultationTimer.seconds
  ).padStart(2, "0");
  // define methods
  const handleHangUpCall = () => {
    setNexmoCallStatus("unanswered");
    handleClickCallScreenDialog();
    setCurrentRescheduleComponent(false);
    setCurrentPatientDeclined(false);

    hangUpCall();
    callTimer.pause();
    callTimer.reset();
  };

  const handleClickCallScreenDialog = () => {
    setOpenCallScreenDialog(true);
  };

  const getIsCallingFromStore = () => {
    const currentNmsState = store.getState().nmsService;
    return currentNmsState?.isCalling || false;
  };

  const hangUpCall = (call = null) => {
    setCurrentIsCalling(false);
    continueCallOrDoHangup(call);

    clearTimeout(callTimeout);
    clearTimeout(warningTimeout);
    setShowWarningCounter(false);
  };

  const continueCallOrDoHangup = (call) => {
    const isCallingStatus = getIsCallingFromStore();
    const callStatusNexmo = call?.status || activeCall?.status || "";
    setNexmoCallStatus(callStatusNexmo);

    if (isCallingStatus === true) {
      updateUserCurrentStatus(3);
      if (callStatusNexmo) {
        if (call) {
          setActiveCall(call);
        }
        setCallStatus(`Calling ${displayPhoneNumber} - ${callStatusNexmo}`);
      } else {
        setCallStatus(`Calling ${displayPhoneNumber}`);
      }
    } else {
      updateUserCurrentStatus(2);
      setCallStatus(`Ending call to ${displayPhoneNumber}`);
      try {
        if (call && call?.hangUp) {
          call?.hangUp();
        } else if (activeCall && activeCall?.hangUp) {
          activeCall?.hangUp();
        }
      } catch (error) {
        console.log(error);
      }

      setActiveCall(null);
    }
  };

  const getPhoneNumberFromStore = () => {
    const currentNmsState = store.getState().nmsService;
    const validatePhoneNumber = phone(
      currentNmsState?.service?.telephoneNumber,
      { country: "GB" }
    );
    const displayPhoneNumber = formatPhone(validatePhoneNumber?.phoneNumber);

    return displayPhoneNumber;
  };

  const handleClickOpenOptionsDialog = () => {
    setOpenOptionsDialog(true);
    ActionTrackForServices(0, true, "Phone Call", currentUser);
    setOpenCallScreenDialog(true);
  };

  const handleConsultationStopClick = () => {
    if (inPersonConsultationTimer) {
      setStopConsultation(true);
      inPersonConsultationTimer.pause();
      setStopConsultationMinutes(formattedConsultationMinutes);
      setStopConsultationSeconds(formattedConsultationSeconds);
      setIsConsultation(false);
    }
  };

  const handleOpenInPersonConsultation = () => {
    inPersonConsultationTimer.reset();
    setStopConsultation(false);
    saveTotalAttempts({ clinicalServiceId, currentIndex })
      .then((result) => {})
      .catch(() => showError("Something went worng", true, false));
    inPersonConsultationTimer.start();
    handleCloseOptionsDialog();
    setIsConsultation(true);
    setCurrentIsCalling(true);
    setOpenInPersonConsultationDialog(true);
  };

  const handleCloseOptionsDialog = () => {
    setOpenOptionsDialog(false);
    setCurrentMultipleNumbersNMSModal(false);
  };

  const handleTopbarIconConsultationClick = () => {
    setOpenInPersonConsultationDialog(true);
  };
  const handleCloseCallScreenDialog = () => {
    setOpenCallScreenDialog(false);
  };
  const handleMakeCall = (phoneNumber) => {
    setDisplayPhoneNumber(phoneNumber);
    addAuditForSkipAndAttempt(0);
    saveTotalAttempts({ clinicalServiceId, currentIndex })
      .then((result) => {})
      .catch(() => showError("Something went wrong", true, false));

    updateTotalAttemptsByClinicalServiceId({ clinicalServiceId })
      .then((result) => {})
      .catch(() => showError("Something went wrong", true, false));

    setNexmoCallStatus(null);

    if (getIsCallingFromStore() === false) {
      setCurrentIsCalling(true);
      initNexmoAndDoCall(nmsServiceState?.pharmacy, phoneNumber);
    }

    setOpenOptionsDialog(false);
    handleClickCallScreenDialog();
    setIsStart(false);
  };

  const getPatientPhoneNumberDetails = async () => {
    const patientId = service?.patientId;
    var data = await getPatientPhoneNumber({ clinicalServiceId, patientId });
    if (data != null) {
      setCurrentMultipleNumbersNMSModal(true);
    }
  };

  const initNexmoAndDoCall = async (paramPharmacy, phoneNumberParam) => {
    try {
      const phoneNumber =
        phoneNumberParam?.replaceAll(" ", "")?.replaceAll("-", "") ||
        phoneNumberParam;
      const validatePhoneNumber = phone(phoneNumber, { country: "GB" });

      const dispPhoneNumber =
        formatPhone(validatePhoneNumber?.phoneNumber) || phoneNumber || "";
      setCallStatus(`Calling ${dispPhoneNumber}`);

      if (validatePhoneNumber?.isValid === false) {
        hangUpCall();
        showError(`"${phoneNumber}" is an invalid UK phone number.`, true);
        return;
      }

      setDisplayPhoneNumber(dispPhoneNumber);

      if (paramPharmacy?.ods) {
        setCallStatus(`Calling ${dispPhoneNumber}`);

        const jwtToken = await getVoiceJWT(paramPharmacy?.ods);
        const isCallingStatus = getIsCallingFromStore();
        if (isCallingStatus === false) {
          return;
        }

        if (jwtToken && jwtToken?.length > 0) {
          setCurrentVoiceJWTToken(jwtToken);
          const nexmo = new nexmoClient();
          setCallStatus(`Calling ${dispPhoneNumber} - initializing`);
          setNexmoCallStatus("initializing");
          nexmo
            .createSession(jwtToken)
            .then((nexmoApp) => {
              nexmoApp.on("member:call", (member, call) => {
                continueCallOrDoHangup(call);
              });

              nexmoApp.on("call:status:changed", (call) => {
                console.log(call.status);
                if (call.status === "answered") {
                  callTimer.reset();
                  callTimer.start();

                  warningTimeout = setTimeout(() => {
                    setShowWarningCounter(true);
                    startWarningCountdown();
                  }, autoCutCallShowWarningTimeout);

                  callTimeout = setTimeout(() => {
                    call.hangUp();
                    hangUpCall();
                  }, autoCutCallTimeout);

                  setCallStartTime(new Date().toISOString());
                } else if (
                  call.status === "completed" ||
                  call.status === "failed" ||
                  call.status === "unanswered" ||
                  call.status === "rejected"
                ) {
                  callTimer.pause();
                  callTimer.reset();
                  setStoppedMinutes(callTimer.minutes);
                  setStoppedSeconds(callTimer.seconds);

                  // Clear the timeouts if the call ends early
                  clearTimeout(callTimeout);
                  clearTimeout(warningTimeout);
                  setShowWarningCounter(false);
                  setCallEndTime(new Date().toISOString());
                  setCurrentIsCalling(false);
                }
                continueCallOrDoHangup(call);
              });
              return nexmoApp;
            })
            .then((nexmoApp) => {
              const isCallingStatus = getIsCallingFromStore();
              if (isCallingStatus === true) {
                const customData = {
                  ods: paramPharmacy?.ods || "",
                  pharmacyId: paramPharmacy?.pharmacyID || "",
                  pharmacyName: paramPharmacy?.pharmacyName || "",
                  clinicalServiceId: clinicalServiceId || "",
                  createdByEmailAddress: userEmail || "",
                  createdByName: userFullName || "",
                  domain: window.location.origin || "",
                };

                nexmoApp.callServer(
                  validatePhoneNumber?.phoneNumber?.replaceAll("+", ""),
                  "phone",
                  customData
                );
              } else {
                continueCallOrDoHangup(null);
              }
            })
            .catch((error) => {
              continueCallOrDoHangup(null);
            });
        } else {
          setCallStatus(`Calling ${dispPhoneNumber} -  permission denied`);
          hangUpCall();
        }
      } else {
        showError("Pharmacy ODS is invalid or not found", true);
        setCallStatus(`Calling ${dispPhoneNumber} -  permission denied`);
        hangUpCall();
      }
    } catch (error) {
      console.log(error);
      showError("Unknown error occurred.", true);
      setCallStatus(`Unknown error`);
      hangUpCall();
    }
  };

  const handleCloseInPersonConsultation = () => {
    setOpenInPersonConsultationDialog(false);
  };

  const handleContinueSessionClick = () => {
    setStopConsultation(false);
    setIsConsultation(true);
    setCurrentIsCalling(true);
    inPersonConsultationTimer.start();
  };

  const handleConsultationCancelClick = () => {
    setIsConsultation(false);
    setCurrentIsCalling(false);
    inPersonConsultationTimer.pause();
    inPersonConsultationTimer.reset();
    handleCloseInPersonConsultation();
  };

  const handleDoneClick = () => {
    inPersonConsultationTimer.reset();
    setStopConsultation(false);
    setCurrentIsCalling(false);
    handleCloseInPersonConsultation();
  };

  const handleSkipNMSClick = () => {
    if (skipCounts > 0) {
      setCurrentSkipNMSModal(true);
    }
  };

  const handleSkipNMSGoBack = () => {
    setCurrentSkipNMSModal(false);
  };

  const handleMultipleNumbersGoBack = () => {
    setCurrentMultipleNumbersNMSModal(false);
  };

  const startWarningCountdown = () => {
    let remainingMinutes = 5;

    const updateCountdown = () => {
      if (remainingMinutes > 0) {
        setWarningCountdownStatus(
          `${remainingMinutes} minute${remainingMinutes === 1 ? "" : "s"} left`
        );
        setTimeout(updateCountdown, 60000);
        remainingMinutes--;
      } else {
        hangUpCall();
      }
    };

    updateCountdown();
  };

  return (
    <>
      <BreadCrumbNMS
        type={"NMS"}
        serviceType={serviceType}
        stageNo={currentIndex}
        stageName={stageName}
        handleHangUpCall={handleHangUpCall}
        handleClickOpenOptionsDialog={handleClickOpenOptionsDialog}
        handleClickCallScreenDialog={handleClickCallScreenDialog}
        nexmoCallStatus={nexmoCallStatus}
        minutes={callTimer.minutes}
        seconds={callTimer.seconds}
        isConsultation={isConsultation}
        handleConsultationStopClick={handleConsultationStopClick}
        formattedConsultationMinutes={formattedConsultationMinutes}
        formattedConsultationSeconds={formattedConsultationSeconds}
        openInPersonConsultationDialog={openInPersonConsultationDialog}
        handleOpenInPersonConsultation={handleOpenInPersonConsultation}
        handleTopbarIconConsultationClick={handleTopbarIconConsultationClick}
        warningCountdownStatus={warningCountdownStatus}
        showWarningCounter={showWarningCounter}
        isStart={isStart}
      />

      {!isStart ||
      called === "completed" ||
      called === "failed" ||
      called === "ended" ||
      called === "rejected" ? (
        <CallingWidget
          openCallScreenDialog={openCallScreenDialog}
          handleCloseCallScreenDialog={handleCloseCallScreenDialog}
          handleHangUpCall={handleHangUpCall}
          handleMakeCall={handleMakeCall}
          nexmoCallStatus={nexmoCallStatus}
          phoneNumber={displayPhoneNumber || service?.telephoneNumber}
          minutes={callTimer.minutes}
          seconds={callTimer.seconds}
          stoppedMinutes={stoppedMinutes}
          stoppedSeconds={stoppedSeconds}
          stageNo={stageNo}
          parkClinicalService={parkClinicalService}
          validateComplete={validateComplete}
          declineNms={declineNms}
          submitNms={submitNms}
          setIsStart={setIsStart}
        />
      ) : (
        <Dialog
          open={openOptionsDialog}
          onClose={handleCloseOptionsDialog}
          aria-labelledby="options-dialog-title"
          aria-describedby="options-dialog-description"
          className="optionsDialog"
        >
          <div className="options_dialog_wrapper">
            {isOrbitUser === false ? (
              <div className="option">
                <div className="flex">
                  <div className="play_btn icon_btn">
                    <FaPlay size={10} />
                  </div>
                  <p>In person consultation</p>
                </div>

                <div
                  className="action"
                  onClick={handleOpenInPersonConsultation}
                >
                  <p>Start</p>
                  <FaArrowRight
                    color={darkMode ? "#fff" : "#0a0a0a"}
                    size={14}
                  />
                </div>
              </div>
            ) : (
              <>
                {skipNMSModal ? (
                  <div className="startCall-NMS-Modal">
                    <div className="top-bar">
                      <div>
                        <RxArrowLeft
                          color={darkMode ? "#fff" : "#0a0a0a"}
                          size={15}
                          className="go-back"
                          onClick={handleSkipNMSGoBack}
                        />
                      </div>
                      <div></div>

                      <RxCross2
                        color={darkMode ? "#fff" : "#0a0a0a"}
                        size={15}
                        className="collapse-icon"
                        onClick={handleCloseOptionsDialog}
                      />
                    </div>
                    <div className="body">
                      <div className="options-wrapper">
                        <div className="option">
                          <div className="flex">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="24"
                                height="24"
                                rx="12"
                                fill="black"
                              />
                              <path
                                d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                                fill="white"
                              />
                            </svg>

                            <p>Skip Nms</p>
                          </div>
                        </div>
                      </div>
                      <textarea
                        value={skipComments}
                        onChange={(e) => setSkipComments(e.target.value)}
                        className="skip-textarea"
                        placeholder="Reason for skipping"
                        id="skip-nms-reason"
                        name="skip-nms-reason"
                        rows="3"
                      ></textarea>
                      <div className="calling-footer">
                        <button
                          className="done"
                          disabled={skipComments ? false : true}
                          onClick={() => {
                            SkipClincialService(skipComments);
                            setSkipComments(null);
                            handleCloseOptionsDialog();
                            setCurrentSkipNMSModal(false);
                            handleCloseOptionsDialog();
                          }}
                        >
                          <p>Done</p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : declineNMSModal ? (
                  <div className="startCall-NMS-Modal">
                    <div className="top-bar">
                      <div>
                        <RxArrowLeft
                          color={darkMode ? "#fff" : "#0a0a0a"}
                          size={15}
                          className="go-back"
                          onClick={() => {
                            setCurrentDeclineNMSModal(false);
                          }}
                        />
                      </div>
                      <div></div>

                      <RxCross2
                        color={darkMode ? "#fff" : "#0a0a0a"}
                        size={15}
                        className="collapse-icon"
                        onClick={handleCloseOptionsDialog}
                      />
                    </div>
                    <div className="body">
                      <div className="options-wrapper">
                        <div className="option">
                          <div className="flex">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="24"
                                height="24"
                                rx="12"
                                fill="black"
                              />
                              <path
                                d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                                fill="white"
                              />
                            </svg>

                            <p>Decline Nms</p>
                          </div>
                        </div>
                      </div>
                      <textarea
                        value={declineComments}
                        onChange={(e) =>
                          setCurrentDeclineComments(e.target.value)
                        }
                        className="skip-textarea"
                        placeholder="Reason for decline"
                        id="skip-nms-reason"
                        name="skip-nms-reason"
                        rows="3"
                      ></textarea>
                      <div className="calling-footer">
                        <button
                          className="done"
                          disabled={declineComments ? false : true}
                          onClick={() => {
                            declineNms();
                            handleCloseOptionsDialog();
                            setCurrentDeclineNMSModal(false);
                          }}
                        >
                          <p>Done</p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : multipleNumbersNMSModal ? (
                  <div className="startCall-NMS-Modal">
                    <div className="top-bar">
                      <div className="icon-label">
                        <RxArrowLeft
                          color={darkMode ? "#fff" : "#0a0a0a"}
                          size={15}
                          className="go-back"
                          onClick={handleMultipleNumbersGoBack}
                        />
                      </div>
                      <div></div>

                      <RxCross2
                        color={darkMode ? "#fff" : "#0a0a0a"}
                        size={15}
                        className="collapse-icon"
                        onClick={handleCloseOptionsDialog}
                      />
                    </div>

                    <div className="body">
                      <div className="options-wrapper">
                        <div className="option">
                          <div className="flex">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="24"
                                height="24"
                                rx="12"
                                fill="black"
                              />
                              <path
                                d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                                fill="white"
                              />
                              <path
                                d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                                fill="white"
                              />
                              <path
                                d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                                fill="white"
                              />
                            </svg>

                            <p>Multiple Numbers</p>
                          </div>
                        </div>
                        <div className="multiple-numbers">
                          {patientsTelephone.map((patTelephone, index) => {
                            if (patTelephone?.isPreferred === true) {
                              return (
                                <>
                                  <p className="preferred">Preferred</p>
                                  <div className="option" key={index}>
                                    <div className="flex">
                                      <div className="call_btn icon_btn">
                                        <PiPhoneFill color="black" />
                                      </div>
                                      <div>
                                        <p>
                                          {
                                            telephoneType[
                                              patTelephone?.contactType
                                            ]
                                          }
                                        </p>
                                        <p className="label-two">
                                          {formatPhoneNumber(
                                            patTelephone?.contactNumber
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="action"
                                      onClick={() =>
                                        handleMakeCall(
                                          patTelephone?.contactNumber
                                        )
                                      }
                                    >
                                      <FaArrowRight color="#0a0a0a" size={14} />
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            } else {
                              return (
                                <div className="option" key={index}>
                                  <div className="flex">
                                    <div className="call_btn icon_btn">
                                      <PiPhoneFill color="black" />
                                    </div>
                                    <div>
                                      <p>
                                        {
                                          telephoneType[
                                            patTelephone?.contactType
                                          ]
                                        }
                                      </p>
                                      <p className="label-two">
                                        {formatPhoneNumber(
                                          patTelephone?.contactNumber
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="action"
                                    onClick={() =>
                                      handleMakeCall(
                                        patTelephone?.contactNumber
                                      )
                                    }
                                  >
                                    <FaArrowRight color="#0a0a0a" size={14} />
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="call-skip-options">
                    <div className="top-bar">
                      <RxCross2
                        color={darkMode ? "#fff" : "#0a0a0a"}
                        size={15}
                        className="collapse-icon"
                        onClick={handleCloseOptionsDialog}
                      />
                    </div>
                    <p className="option-title">
                      Start the Nms by calling the patient
                    </p>
                    <div className="option">
                      <div className="flex">
                        <div className="call_btn icon_btn">
                          <PiPhoneFill color="black" />
                        </div>
                        <div>
                          <p>Call Patient</p>
                          <p className="label-two">
                            {/* {formatPhoneNumber(
                              displayPhoneNumber || service?.telephoneNumber
                            )} */}
                            Multiple Numbers
                          </p>
                        </div>
                      </div>
                      <div
                        className="action"
                        onClick={getPatientPhoneNumberDetails}
                      >
                        <FaArrowRight color="#0a0a0a" size={14} />
                      </div>
                    </div>
                    <div className="skip">
                      <p className="option-title">
                        If you can't perform the Nms
                      </p>
                      <div className="option">
                        <div className="flex">
                          <div className="icon_btn">
                            <PiPlayFill color={darkMode ? "#0a0a0a" : "#fff"} />
                          </div>
                          <div>
                            <p>Skip Nms</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="tag">
                            <p>{skipCounts > 0 ? skipCounts : 0} remaining </p>
                          </div>
                          <div
                            className="action skip_nms"
                            onClick={handleSkipNMSClick}
                          >
                            <FaArrowRight color="#0a0a0a" size={14} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="skip">
                      <div className="option">
                        <div className="flex">
                          <div className="icon_btn">
                            <PiPlayFill color={darkMode ? "#0a0a0a" : "#fff"} />
                          </div>
                          <div>
                            <p>Decline Nms</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div
                            className="action skip_nms"
                            onClick={() => {
                              if (stageNo === 3) {
                                handleCloseOptionsDialog();
                                setIsFollowUpControl(true);
                              } else {
                                setCurrentDeclineNMSModal(true);
                              }
                            }}
                          >
                            <FaArrowRight color="#0a0a0a" size={14} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Dialog>
      )}
      {isFollowUpControl && (
        <FollowUpModal isOpen={true} handleAcceptConsent={declineNms} />
      )}
      <InPersonConsultation
        openInPersonConsultationDialog={openInPersonConsultationDialog}
        handleCloseInPersonConsultation={handleCloseInPersonConsultation}
        handleConsultationStopClick={handleConsultationStopClick}
        stopConsultation={stopConsultation}
        stopConsultationMinutes={stopConsultationMinutes}
        stopConsultationSeconds={stopConsultationSeconds}
        handleDoneClick={handleDoneClick}
        formattedConsultationMinutes={formattedConsultationMinutes}
        formattedConsultationSeconds={formattedConsultationSeconds}
        handleContinueSessionClick={handleContinueSessionClick}
        handleConsultationCancelClick={handleConsultationCancelClick}
      />
    </>
  );
}

export default ServicesHeader;
