import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import useTitanApp from "../../../../../hooks/useTitanApp";
import moment from "moment";

const CustomGridListLabels = ({
  label,
  rowData,
  smsQueue,
  reSmsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  setCurrentSmsQueue,
  setCurrentReSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  checkedAllEligible,
  setCheckedAllEligible,
  checkedAllConsent,
  setCheckedAllConsent,
  checkedAllIntervention,
  setCheckedAllIntervention,
  checkedAllFollowUp,
  setCheckedAllFollowUp,
  checksCount,
  setChecksCount,
  parentLevel,
  childrenAfterFilter,
  nodeData,
  setRowData,
}) => {
  const { appState } = useTitanApp();
  const { currentPharmacy, currentUser } = appState || {};

  const getLabel = () => {
    switch (label) {
      case "Eligible":
        return "Eligible";
      case "Awaiting consent":
        return "Awaiting Consent";
      case "Intervention":
        return "Intervention";
      case "Follow up":
        return "Follow Up";
      default:
        return null;
    }
  };

  const handleChecked = (e) => {
    const filteredData = nodeData?.childrenAfterFilter;
    filteredData?.map((row) => {
      row?.allLeafChildren?.map((leafData) => {
        const { data, firstChild, displayed } = leafData;

        if (displayed && firstChild) {
          addServiceInQueues(e, data);
          if (e?.target?.checked) {
            setChecksCount(filteredData?.length);
          } else {
            setChecksCount(0);
          }
        } else if (parentLevel === 0) {
          addServiceInQueues(e, data);
          if (e?.target?.checked) {
            setChecksCount(filteredData?.length);
          } else {
            setChecksCount(0);
          }
        }
      });
    });
    switch (label) {
      case "Eligible":
        setCheckedAllEligible(!checkedAllEligible);
        break;
      case "Awaiting consent":
        setCheckedAllConsent(!checkedAllConsent);
        break;
      case "Intervention":
        setCheckedAllIntervention(!checkedAllIntervention);
        break;
      case "Follow up":
        setCheckedAllFollowUp(!checkedAllFollowUp);
        break;
      default:
        break;
    }
  };

  function isChecked() {
    switch (label) {
      case "Eligible":
        return checkedAllEligible;
      case "Awaiting consent":
        return checkedAllConsent;
      case "Intervention":
        return checkedAllIntervention;
      case "Follow up":
        return checkedAllFollowUp;
      default:
        return false;
    }
  }

  const addServiceInQueues = (event, data) => {
    const { service, stageType } = data;
    const addedService = service;
    const { checked } = event?.target || {};
    switch (stageType) {
      case 1:
        if (checked) {
          const obj = {
            patientID: addedService?.patientId,
            patientName: addedService?.patientName,
            pharmacyID: addedService?.pharmacyID,
            message: "",
            clinicalServiceID: addedService?._id,
            pharmacyName: currentPharmacy?.teamPharmacyName,
            ods: "",
          };

          let newArrSmsQueue = smsQueue;
          newArrSmsQueue.push(obj);
          setCurrentSmsQueue(newArrSmsQueue);

          let newArrInterventionQueue = interventionQueue;
          newArrInterventionQueue.push(addedService?._id);
          setCurrentInterventionQueue(newArrInterventionQueue);

          let newArrRescheduleQueue = rescheduleQueue;
          newArrRescheduleQueue.push(addedService?._id);
          setCurrentRescheduleQueue(newArrRescheduleQueue);

          let newArrPdsQueueQueue = pdsQueue;
          newArrPdsQueueQueue.push(addedService?._id);
          setCurrentPdsQueue(newArrPdsQueueQueue);
          let newArrDeclineQueue = declineQueue;
          if (!newArrDeclineQueue.includes(addedService?._id)) {
            newArrDeclineQueue.push(addedService?._id);
            setCurrentDeclineQueue(newArrDeclineQueue);
          }
        } else {
          let newArraySmsQueue = smsQueue;
          newArraySmsQueue.pop(
            (obj) => obj?.clinicalServiceID === addedService?._id
          );

          let newArrayInterventionQueue = interventionQueue;
          newArrayInterventionQueue.pop(addedService?._id);

          let newArrayDeclineQueue = declineQueue;
          newArrayDeclineQueue.pop(addedService?._id);

          let newArrayRescheduleQueue = rescheduleQueue;
          newArrayRescheduleQueue.pop(addedService?._id);

          let newArrayPdsQueue = pdsQueue;
          newArrayPdsQueue.pop(addedService?._id);

          setCurrentSmsQueue(newArraySmsQueue);
          setCurrentInterventionQueue(newArrayInterventionQueue);
          setCurrentDeclineQueue(newArrayDeclineQueue);
          setCurrentRescheduleQueue(newArrayRescheduleQueue);
          setCurrentPdsQueue(newArrayPdsQueue);
        }
        break;
      case 2:
        if (checked) {
          const obj = {
            patientID: addedService?.patientId,
            patientName: addedService?.patientName,
            pharmacyID: addedService?.pharmacyID,
            message: "",
            clinicalServiceID: addedService?._id,
            pharmacyName: currentPharmacy?.teamPharmacyName,
            ods: "",
          };
          let newArrSmsQueue = reSmsQueue;
          newArrSmsQueue.push(obj);
          setCurrentReSmsQueue(newArrSmsQueue);

          let newArrInterventionQueue = interventionQueue;
          newArrInterventionQueue.push(addedService?._id);
          setCurrentInterventionQueue(newArrInterventionQueue);

          let newArrRescheduleQueue = rescheduleQueue;
          newArrRescheduleQueue.push(addedService?._id);
          setCurrentRescheduleQueue(newArrRescheduleQueue);

          let newArrPdsQueueQueue = pdsQueue;
          newArrPdsQueueQueue.push(addedService?._id);
          setCurrentPdsQueue(newArrPdsQueueQueue);

          let newArrDeclineQueue = declineQueue;
          newArrDeclineQueue.push(addedService?._id);
          setCurrentDeclineQueue(newArrDeclineQueue);
        } else {
          let newArraySmsQueue = smsQueue;
          newArraySmsQueue.pop(
            (obj) => obj?.clinicalServiceID === addedService?._id
          );

          let newArrayInterventionQueue = interventionQueue;
          newArrayInterventionQueue.pop(addedService?._id);

          let newArrayDeclineQueue = declineQueue;
          newArrayDeclineQueue.pop(addedService?._id);

          let newArrayRescheduleQueue = rescheduleQueue;
          newArrayRescheduleQueue.pop(addedService?._id);

          let newArrayPdsQueue = pdsQueue;
          newArrayPdsQueue.pop(addedService?._id);
          setCurrentReSmsQueue(newArraySmsQueue);

          setCurrentDeclineQueue(newArrayDeclineQueue);
          setCurrentRescheduleQueue(newArrayRescheduleQueue);
          setCurrentPdsQueue(newArrayPdsQueue);
        }
        break;
      case 3:
        if (checked) {
          const serviceDueDateTime = moment(
            addedService?.stages.find((stage) => stage?.stageType === 3)
              ?.startDate
          ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          const obj = {
            pharmacyId: addedService?.pharmacyID,
            serviceId: addedService?.serviceID,
            clinicalServiceID: addedService?._id,
            serviceType: 1,
            serviceCode: 1,
            patientId: addedService?.patientId,
            patientTelNumber: addedService?.telephoneNumber,
            patientName: addedService?.patientName,
            priorityWeighting: "0",
            rate: "",
            serviceDueDateTime: serviceDueDateTime,
            addedToQueueDateTime: new Date(),
            status: 1,
            bookingType: 1,
            sentToOrbitUser: currentUser?.fullName,
            userPickedBy: "",
            stageType: 3,
          };

          let newArrOrbitQueue = orbitQueue;
          newArrOrbitQueue.push(obj);
          setCurrentOrbitQueue(newArrOrbitQueue);

          let newArrRescheduleQueue = rescheduleQueue;
          newArrRescheduleQueue.push(addedService?._id);
          setCurrentRescheduleQueue(newArrRescheduleQueue);

          let newArrPdsQueue = pdsQueue;
          newArrPdsQueue.push(addedService?._id);
          setCurrentPdsQueue(newArrPdsQueue);

          let newArrDeclineQueue = declineQueue;
          newArrDeclineQueue.push(addedService?._id);
          setCurrentDeclineQueue(newArrDeclineQueue);
        } else {
          let newArrayOrbitQueue = orbitQueue;
          newArrayOrbitQueue.pop(
            (obj) => obj?.clinicalServiceID === addedService?._id
          );

          let newArrayDeclineQueue = declineQueue;
          newArrayDeclineQueue.pop(addedService?._id);

          let newArrayRescheduleQueue = rescheduleQueue;
          newArrayRescheduleQueue.pop(addedService?._id);

          let newArrayPdsQueue = pdsQueue;
          newArrayPdsQueue.pop(addedService?._id);

          setCurrentOrbitQueue(newArrayOrbitQueue);
          setCurrentDeclineQueue(newArrayDeclineQueue);
          setCurrentRescheduleQueue(newArrayRescheduleQueue);
          setCurrentPdsQueue(newArrayPdsQueue);
        }
        break;
      case 4:
        if (checked) {
          let newArrRescheduleQueue = rescheduleQueue;
          newArrRescheduleQueue.push(addedService?._id);
          setCurrentRescheduleQueue(newArrRescheduleQueue);

          let newArrPdsQueueQueue = pdsQueue;
          newArrPdsQueueQueue.push(addedService?._id);
          setCurrentPdsQueue(newArrPdsQueueQueue);

          let newArrDeclineQueue = declineQueue;
          newArrDeclineQueue.push(addedService?._id);
          setCurrentDeclineQueue(newArrDeclineQueue);
        } else {
          let newArrayDeclineQueue = declineQueue;
          newArrayDeclineQueue.pop(addedService?._id);

          let newArrayRescheduleQueue = rescheduleQueue;
          newArrayRescheduleQueue.pop(addedService?._id);

          let newArrayPdsQueue = pdsQueue;
          newArrayPdsQueue.pop(addedService?._id);

          setCurrentDeclineQueue(newArrayDeclineQueue);
          setCurrentRescheduleQueue(newArrayRescheduleQueue);
          setCurrentPdsQueue(newArrayPdsQueue);
        }
        break;
    }
  };

  return (
    <div className="custom-grid-label-wrapper">
      <div>
        <Checkbox
          edge="start"
          tabIndex={-1}
          checked={isChecked()}
          onClick={(e) => {
            handleChecked(e);
          }}
          size="small"
          disableRipple
        />
      </div>
      <p>{getLabel()}</p>
    </div>
  );
};

export default CustomGridListLabels;
