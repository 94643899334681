import { dispatch, getDataFromStore } from "../store/store";
import {
  getClinicalServiceByClinicalServiceIdData,
  getClinicalServiceData,
  getClinicalServiceFromOrbitData,
  getTitanPatientData,
  getTitanPatientPdsData,
  getFollowUpByServiceIdAndServiceTypeData,
  getPharmacyIdForOrbitUserData,
  getVoiceJWTData,
  getTimelineData,
  addNmsData,
  searchDrugData,
  searchPatientData,
  addFollowUpData,
  setIsNmsLoading,
  setIsNmsPatientLoading,
  setIsNmsTimelineLoading,
  setIsPatientNmsTelephoneLoading,
  setIsNmsPrescriptionLoading,
  setIsServiceNotesLoading,
  setClinicalServiceData,
  setPatientData,
  setService,
  setPatientName,
  setPharmacyNms,
  setPharmacyId,
  setClinicalpatientId,
  setClinicalServiceId,
  setArrayImg,
  setIsPrescriptionImages,
  setStepNoNms,
  setstageNoNms,
  setNmsModelNms,
  setvalueNms,
  setIsShowEndCall,
  setIsCalling,
  setIsFollowupDescription,
  setIsFollowupDate,
  resetNmsState,
  setVoiceJWTToken,
  addTimelineData,
  addTimelineItemData,
  setTimelineElements,
  setPatientNMSData,
  getRecordingByConversationIdData,
  saveTotalAttemptsData,
  addServiceNotesData,
  getServiceNotesData,
  getPatientByTelephoneAndStageIndexData,
  updateTotalAttemptsByClinicalServiceIdData,
  skipOrbitByClinicalServiceIdData,
  backOrbitByClinicalServiceIdData,
  parkOrbitByClinicalServiceIdData,
  setRescheduleComponent,
  setPatientDeclined,
  setSkipNMSModal,
  setDeclineNMSModal,
  setMultipleNumbersNMSModal,
  setDeclineComments,
  setFollowUpcomments,
  getTotalSkipsData,
  setGroupedPatientWarning,
  setPatientCountData,
  setSkipCountData,
  setShowNMSNotComplete,
  setTempClinicalServiceId,
  setOpenCallScreenDialogData,
  setCallStartTimeData,
  setCallEndTimeData,
  setIsFollowUpControlData,
  setEditedPreferredContact,
  getPatientPhoneNumberData,
} from "../features/NmsServiceSlice/NmsServiceSlice";
import { logError } from "../services/logService";

const reducerName = "nmsService";

// #region State Setters

export const setCurrentArrayImg = (images) => {
  dispatch(setArrayImg(images));
};
export const setPrescriptionImages = (images) => {
  dispatch(setIsPrescriptionImages(images));
};

export const setCurrentIsNmsLoading = (value) => {
  dispatch(setIsNmsLoading(value));
};
export const setCurrentIsNmsPatientLoading = (value) => {
  dispatch(setIsNmsPatientLoading(value));
};
export const setCurrentIsNmsTimelineLoading = (value) => {
  dispatch(setIsNmsTimelineLoading(value));
};
export const setCurrentIsPatientNmsTelephoneLoading = (value) => {
  dispatch(setIsPatientNmsTelephoneLoading(value));
};
export const setCurrentIsNmsPrescriptionLoading = (value) => {
  dispatch(setIsNmsPrescriptionLoading(value));
};
export const setCurrentIsServiceNotesLoading = (value) => {
  dispatch(setIsServiceNotesLoading(value));
};
export const setCurrentClinicalServiceData = (value) => {
  dispatch(setClinicalServiceData(value));
};
export const setCurrentPatientData = (value) => {
  dispatch(setPatientData(value));
};
export const setCurrentService = (value) => {
  dispatch(setService(value));
};
export const setCurrentPatientName = (value) => {
  dispatch(setPatientName(value));
};
export const setCurrentPharmacyNms = (value) => {
  dispatch(setPharmacyNms(value));
};
export const setCurrentNMSPharmacyId = (value) => {
  dispatch(setPharmacyId(value));
};
export const setCurrentClinicalpatientId = (value) => {
  dispatch(setClinicalpatientId(value));
};
export const setCurrentClinicalServiceId = (value) => {
  dispatch(setClinicalServiceId(value));
};
export const setCurrentStepNoNms = (value) => {
  dispatch(setStepNoNms(value));
};
export const setCurrentstageNoNms = (value) => {
  dispatch(setstageNoNms(value));
};
export const setCurrentNmsModel = (value) => {
  dispatch(setNmsModelNms({ ...value }));
};
export const setCurrentvalueNms = (value) => {
  dispatch(setvalueNms(value));
};
export const setCurrentShowEndCall = (value) => {
  dispatch(setIsShowEndCall(value));
};
export const setCurrentIsCalling = (value) => {
  dispatch(setIsCalling(value));
};
export const setCurrentVoiceJWTToken = (value) => {
  dispatch(setVoiceJWTToken(value));
};
export const setFollowupDescription = (description) => {
  dispatch(setIsFollowupDescription(description));
};
export const setFollowupDate = (date) => {
  dispatch(setIsFollowupDate(date));
};
export const setCurrentTimelineElements = (value) => {
  dispatch(setTimelineElements(value));
};
export const setCurrentPatientNMSData = (value) => {
  dispatch(setPatientNMSData(value));
};
export const setCurrentRescheduleComponent = (value) => {
  dispatch(setRescheduleComponent(value));
};
export const setCurrentPatientDeclined = (value) => {
  dispatch(setPatientDeclined(value));
};
export const setCurrentSkipNMSModal = (value) => {
  dispatch(setSkipNMSModal(value));
};
export const setCurrentDeclineNMSModal = (value) => {
  dispatch(setDeclineNMSModal(value));
};
export const setCurrentMultipleNumbersNMSModal = (value) => {
  dispatch(setMultipleNumbersNMSModal(value));
};
export const setCurrentDeclineComments = (value) => {
  dispatch(setDeclineComments(value));
};
export const setCurrentFollowUpcomments = (value) => {
  dispatch(setFollowUpcomments(value));
};
export const setCurrentGroupedPatientWarning = (value) => {
  dispatch(setGroupedPatientWarning(value));
};
export const setCurrentPatientCountData = (value) => {
  dispatch(setPatientCountData(value));
};
export const setCurrentSkipCountData = (value) => {
  dispatch(setSkipCountData(value));
};
export const setCurrentShowNMSNotComplete = (value) => {
  dispatch(setShowNMSNotComplete(value));
};
export const setCurrentTempClinicalServiceId = (value) => {
  dispatch(setTempClinicalServiceId(value));
};
export const setOpenCallScreenDialog = (value) => {
  dispatch(setOpenCallScreenDialogData(value));
};
export const setIsFollowUpControl = (value) => {
  dispatch(setIsFollowUpControlData(value));
};
export const setCallStartTime = (value) => {
  dispatch(setCallStartTimeData(value));
};
export const setCallEndTime = (value) => {
  dispatch(setCallEndTimeData(value));
};
export const setCurrentEditedPreferredContact = (value) => {
  dispatch(setEditedPreferredContact(value));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getClinicalServiceByClinicalServiceId = async (
  clinicalServiceId
) => {
  let result = null;
  try {
    result = await dispatch(
      getClinicalServiceByClinicalServiceIdData({
        clinicalServiceId,
      })
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getClinicalService = async (currentPharmacyId, isOrbitUser) => {
  let result = null;
  try {
    result = await dispatch(
      getClinicalServiceData({
        currentPharmacyId,
        isOrbitUser,
      })
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getClinicalServiceFromOrbit = async (params) => {
  let result = null;
  try {
    result = await dispatch(getClinicalServiceFromOrbitData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getPharmacyIdForOrbitUser = async () => {
  let result = [];
  try {
    result = await dispatch(getPharmacyIdForOrbitUserData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getTitanPatient = async (params) => {
  let result = [];
  try {
    result = await dispatch(getTitanPatientData(params)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getTitanPatientPds = async (params) => {
  let result = null;
  try {
    result = await dispatch(getTitanPatientPdsData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getVoiceJWT = async (params) => {
  let result = null;
  try {
    result = await dispatch(getVoiceJWTData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const getFollowUpByServiceIdAndServiceType = async (
  serviceId,
  serviceType,
  stageNo
) => {
  let result = [];
  try {
    result = await dispatch(
      getFollowUpByServiceIdAndServiceTypeData({
        serviceId,
        serviceType,
        stageNo,
      })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getPatientByTelephoneAndStageIndexResult = async (
  telephone,
  value,
  serviceId,
  userSessionId,
  isOrbitUser
) => {
  let result = [];
  try {
    result = await dispatch(
      getPatientByTelephoneAndStageIndexData({
        telephone,
        value,
        serviceId,
        userSessionId,
        isOrbitUser,
      })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const addNms = async (data, isOrbitUser) => {
  let result = null;
  try {
    result = await dispatch(addNmsData({ data, isOrbitUser })).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const searchDrug = async (data) => {
  let result = null;
  try {
    result = await dispatch(searchDrugData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const searchPatient = async (data) => {
  let result = null;
  try {
    result = await dispatch(searchPatientData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const addFollowUp = async (data) => {
  let result = null;
  try {
    result = await dispatch(addFollowUpData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addTimeline = async (data) => {
  let result = null;
  try {
    result = await dispatch(addTimelineData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addTimelineItem = async (data) => {
  let result = null;
  try {
    result = await dispatch(addTimelineItemData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getTimeline = async (params) => {
  let result = null;
  try {
    result = await dispatch(getTimelineData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getRecordingByConversationId = async (conversationId) => {
  let result = null;
  try {
    result = await dispatch(
      getRecordingByConversationIdData(conversationId)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const saveTotalAttempts = async (params) => {
  let result = null;
  try {
    result = await dispatch(saveTotalAttemptsData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const updateTotalAttemptsByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(
      updateTotalAttemptsByClinicalServiceIdData(params)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const skipOrbitByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(skipOrbitByClinicalServiceIdData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const getTotalSkips = async (params) => {
  let result = null;
  try {
    result = await dispatch(getTotalSkipsData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const backOrbitByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(backOrbitByClinicalServiceIdData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const parkOrbitByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(parkOrbitByClinicalServiceIdData(params)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const addServiceNotes = async (data) => {
  let result = null;
  try {
    result = await dispatch(addServiceNotesData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getServiceNotes = async (params) => {
  let result = [];
  try {
    result = await dispatch(getServiceNotesData(params)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const getPatientPhoneNumber = async (params) => {
  let result = [];
  try {
    result = await dispatch(getPatientPhoneNumberData(params)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

// #endregion
