import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { RxOpenInNewWindow, RxCross2, RxArrowLeft } from "react-icons/rx";

import Dialog from "@mui/material/Dialog";
import { FaPhone, FaCircle, FaArrowRight } from "react-icons/fa";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../../components/controls/CustomLoader";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  updateCalendarEventByClinicalServiceId,
  updateRecheduleByClinicalServiceId,
} from "../../../dispatchers/pharmacyServiceDispatchers";
import moment from "moment";
import {
  backOrbitByClinicalServiceId,
  getFollowUpByServiceIdAndServiceType,
  setCurrentDeclineComments,
  setCurrentPatientDeclined,
  setCurrentRescheduleComponent,
  setIsFollowUpControl,
} from "../../../dispatchers/nmsDispatchers";
import { getUpdatedDetailForNMS } from "../../../data/getNmsInterventionData";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdCall, MdKeyboardBackspace } from "react-icons/md";
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";
import { formatPhoneNumber } from "../../../data/formatPhone";
import DPIcon from "./../../../assets/images/dp-icon.png";
import Ringing from "./CallingWidget/Ringing";
import Answered from "./CallingWidget/Answered";
import Rescheduling from "./CallingWidget/Rescheduling";
import PatientDeclined from "./CallingWidget/PatientDeclined";
import Unanswered from "./CallingWidget/Unanswered";
import EndGroupPatients from "./CallingWidget/EndGroupPatients";
import EndNMSNotComplete from "./CallingWidget/EndNMSNotComplete";
import EndNMSComplete from "./CallingWidget/EndNMSComplete";
import { useEffect } from "react";

export default function CallingWidget({
  openCallScreenDialog,
  handleCloseCallScreenDialog,
  handleHangUpCall,
  handleMakeCall,
  nexmoCallStatus,
  minutes,
  seconds,
  phoneNumber,
  stoppedMinutes,
  stoppedSeconds,
  stageNo,
  parkClinicalService,
  validateComplete,
  declineNms,
  submitNms,
  setIsStart,
}) {
  const { nmsServiceState, appState, NmsLoader } = useTitanApp();
  const { darkMode, currentUser } = appState || {};
  const userFullName = currentUser?.fullName || "";
  let navigate = useNavigate();
  const {
    service,
    pharmacy,
    clinicalServiceId,
    followupDate,
    rescheduleComponent,
    patientDeclined,
    value,
    declineComments,
    patientNmsData,
    patientCount,
    showNMSNotComplete,
  } = nmsServiceState || {};
  const { pharmacyName } = pharmacy || "";
  const formattedCallMinutes = String(minutes).padStart(2, "0");
  const formattedCallSeconds = String(seconds).padStart(2, "0");
  const called = localStorage.getItem(`${clinicalServiceId}_called`);

  useEffect(() => {
    if (
      nexmoCallStatus === "completed" ||
      nexmoCallStatus === "failed" ||
      nexmoCallStatus === "ended" ||
      nexmoCallStatus === "rejected"
    ) {
      localStorage.setItem(`${clinicalServiceId}_called`, nexmoCallStatus);
    }
  }, [nexmoCallStatus]);
  const [startDate, setStartDate] = useState(followupDate);
  const [additionalComments, setAdditionalComments] = useState("");
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  function alreadyCalled() {
    let callStatus = false;
    callStatus =
      called ||
      called === "completed" ||
      called === "failed" ||
      called === "ended" ||
      called === "rejected";
    return callStatus;
  }
  const handleRescheduleFollowup = async () => {
    const parsedDate = moment(
      startDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)"
    );

    const formattedDateTimeCheck = parsedDate.format("YYYY-MM-DD HH:mm:ss");
    const formattedDateTime = parsedDate.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    const parsedFollowupdate = moment(
      followupDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)"
    );

    const formattedFollowupDateTime = parsedFollowupdate.format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const obj = {
      clinicalServiceID: clinicalServiceId,
      notes: additionalComments === "" ? "reschedule" : additionalComments,
      userName: userFullName,
      date: new Date(),
      stageType: stageNo,
    };
    if (formattedDateTimeCheck !== formattedFollowupDateTime) {
      updateRecheduleByClinicalServiceId({
        formattedDateTime,
        clinicalServiceId,
        stageNo,
        data: obj,
      }).then((data) => {
        getFollowUpByServiceIdAndServiceType(clinicalServiceId, 1, 1);
        if (selectedRescheduleOption === "rescheduleMyself") {
          parkClinicalService();
        } else {
          backOrbitByClinicalServiceId(clinicalServiceId).then((data) => {
            navigate(`/orbit`);
          });
        }
        handleCloseCallScreenDialog();
      });
    } else {
      handleCloseCallScreenDialog();
    }
  };

  const handleRescheduleClick = () => {
    setCurrentRescheduleComponent(true);
  };

  const handleRescheduleGoBack = () => {
    setCurrentRescheduleComponent(false);
  };
  const handlePatientDeclinedClick = () => {
    if (stageNo === 3) {
      handleCloseCallScreenDialog();
      setIsFollowUpControl(true);
    } else {
      setCurrentPatientDeclined(true);
    }
  };

  const handlePatientDeclinedGoBack = () => {
    setCurrentPatientDeclined(false);
  };

  const [selectedRescheduleOption, setSelectedRescheduleOption] = useState(
    "reschedulePharmacist"
  );

  const handleRescheduleOptionClick = (option) => {
    setSelectedRescheduleOption(option);
  };

  const [initializingPosition, setInitializingPosition] = useState({
    x: 0,
    y: 0,
  });
  const [ringingPosition, setRingingPosition] = useState({ x: 0, y: 0 });
  const [answeredPosition, setAnsweredPosition] = useState({ x: 0, y: 0 });
  const [unansweredPosition, setUnansweredPosition] = useState({ x: 0, y: 0 });
  const [endedPosition, setEndedPosition] = useState({ x: 0, y: 0 });
  const [reschedulingPosition, setReschedulingPosition] = useState({
    x: 0,
    y: 0,
  });
  const [patientDeclinedPosition, setPatientDeclinedPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleInitializingDrag = (e, ui) => {
    const { x, y } = initializingPosition;
    setInitializingPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setRingingPosition(initializingPosition);
  };

  const handleRingingDrag = (e, ui) => {
    const { x, y } = ringingPosition;
    setRingingPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setUnansweredPosition(ringingPosition);
  };

  const handleAnsweredDrag = (e, ui) => {
    const { x, y } = answeredPosition;
    setAnsweredPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setEndedPosition(answeredPosition);
  };

  const handleEndedDrag = (e, ui) => {
    const { x, y } = endedPosition;
    setEndedPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setPatientDeclinedPosition(endedPosition);
    setReschedulingPosition(endedPosition);
  };

  const handleUnansweredDrag = (e, ui) => {
    const { x, y } = unansweredPosition;
    setUnansweredPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setReschedulingPosition(unansweredPosition);
  };

  const handleReschedulingDrag = (e, ui) => {
    const { x, y } = reschedulingPosition;
    setReschedulingPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setUnansweredPosition(reschedulingPosition);
  };
  const handlePatientDeclinedDrag = (e, ui) => {
    const { x, y } = patientDeclinedPosition;
    setPatientDeclinedPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    setEndedPosition(patientDeclinedPosition);
  };

  return openCallScreenDialog ? (
    nexmoCallStatus === "initializing" ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={initializingPosition}
        onDrag={handleInitializingDrag}
      >
        <Paper className="callScreenDialog">
          <CustomLoader />
        </Paper>
      </Draggable>
    ) : nexmoCallStatus === "started" || nexmoCallStatus === "ringing" ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={ringingPosition}
        onDrag={handleRingingDrag}
      >
        <Paper className="callScreenDialog">
          <Ringing
            handleCloseCallScreenDialog={handleCloseCallScreenDialog}
            service={service}
            phoneNumber={phoneNumber}
            handleHangUpCall={handleHangUpCall}
          />
        </Paper>
      </Draggable>
    ) : nexmoCallStatus === "answered" ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={answeredPosition}
        onDrag={handleAnsweredDrag}
      >
        <Paper className="callScreenDialog">
          <Answered
            handleCloseCallScreenDialog={handleCloseCallScreenDialog}
            service={service}
            phoneNumber={phoneNumber}
            userFullName={userFullName}
            value={value}
            patientNmsData={patientNmsData}
            pharmacyName={pharmacyName}
            validateComplete={validateComplete}
            submitNms={submitNms}
            formattedCallSeconds={formattedCallSeconds}
            formattedCallMinutes={formattedCallMinutes}
            handleHangUpCall={handleHangUpCall}
          />
        </Paper>
      </Draggable>
    ) : rescheduleComponent ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={reschedulingPosition}
        onDrag={handleReschedulingDrag}
      >
        <Paper className="callScreenDialog">
          <Rescheduling
            handleRescheduleGoBack={handleRescheduleGoBack}
            selectedRescheduleOption={selectedRescheduleOption}
            handleRescheduleOptionClick={handleRescheduleOptionClick}
            setStartDate={setStartDate}
            startDate={startDate}
            followupDate={followupDate}
            currentHour={currentHour}
            currentMinute={currentMinute}
            setAdditionalComments={setAdditionalComments}
            additionalComments={additionalComments}
            handleRescheduleFollowup={handleRescheduleFollowup}
            phoneNumber={phoneNumber}
            handleCloseCallScreenDialog={handleCloseCallScreenDialog}
          />
        </Paper>
      </Draggable>
    ) : patientDeclined ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={patientDeclinedPosition}
        onDrag={handlePatientDeclinedDrag}
      >
        <Paper className="callScreenDialog">
          <PatientDeclined
            declineNms={declineNms}
            handleCloseCallScreenDialog={handleCloseCallScreenDialog}
          />
        </Paper>
      </Draggable>
    ) : nexmoCallStatus === "unanswered" ? (
      <Draggable
        bounds="parent"
        cancel={'[class*="MuiDialogContent-root"]'}
        position={unansweredPosition}
        onDrag={handleUnansweredDrag}
      >
        <Paper className="callScreenDialog">
          <Unanswered
            handleRescheduleClick={handleRescheduleClick}
            parkClinicalService={parkClinicalService}
            handleMakeCall={handleMakeCall}
            handleRescheduleFollowup={handleRescheduleFollowup}
            service={service}
            phoneNumber={phoneNumber}
            handleCloseCallScreenDialog={handleCloseCallScreenDialog}
            setIsStart={setIsStart}
          />
        </Paper>
      </Draggable>
    ) : nexmoCallStatus === "completed" ||
      nexmoCallStatus === "failed" ||
      nexmoCallStatus === "ended" ||
      nexmoCallStatus === "rejected" ||
      alreadyCalled() ? (
      patientNmsData?.length > 1 ? (
        <Draggable
          bounds="parent"
          cancel={'[class*="MuiDialogContent-root"]'}
          position={endedPosition}
          onDrag={handleEndedDrag}
        >
          <Paper className="callScreenDialog">
            <EndGroupPatients
              patientCount={patientCount}
              submitNms={submitNms}
              phoneNumber={phoneNumber}
              value={value}
              patientNmsData={patientNmsData}
              handleRescheduleClick={handleRescheduleClick}
              handleMakeCall={handleMakeCall}
              handleCloseCallScreenDialog={handleCloseCallScreenDialog}
              validateComplete={validateComplete}
            />
          </Paper>
        </Draggable>
      ) : showNMSNotComplete || validateComplete(value) === true ? (
        <Draggable
          bounds="parent"
          cancel={'[class*="MuiDialogContent-root"]'}
          position={endedPosition}
          onDrag={handleEndedDrag}
        >
          <Paper className="callScreenDialog">
            <EndNMSNotComplete
              phoneNumber={phoneNumber}
              handlePatientDeclinedClick={handlePatientDeclinedClick}
              handleCloseCallScreenDialog={handleCloseCallScreenDialog}
              handleRescheduleClick={handleRescheduleClick}
              handleMakeCall={handleMakeCall}
            />
          </Paper>
        </Draggable>
      ) : (
        <Draggable
          bounds="parent"
          cancel={'[class*="MuiDialogContent-root"]'}
          position={endedPosition}
          onDrag={handleEndedDrag}
        >
          <Paper className="callScreenDialog">
            <EndNMSComplete
              phoneNumber={phoneNumber}
              handleCloseCallScreenDialog={handleCloseCallScreenDialog}
              submitNms={submitNms}
              value={value}
              handleMakeCall={handleMakeCall}
            />
          </Paper>
        </Draggable>
      )
    ) : (
      <Draggable bounds="parent" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper className="callScreenDialog">
          <CustomLoader />
        </Paper>
      </Draggable>
    )
  ) : null;
}
