import React, { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import { GoPencil } from "react-icons/go";
import useTitanApp from "../../../hooks/useTitanApp";

function NMSGroupedNotes({ extractedNotesComments }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <GoPencil size={26} color={darkMode ? "#f2f2f2" : "#0a0a0a"} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <h6>
          <span className="typeHeading">Notes</span>
        </h6>
        <Accordion className="timeline_accordion" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="heading view-content-heading"
          >
            <p className="view-contents">View contents</p>
          </AccordionSummary>
          <AccordionDetails>
            {extractedNotesComments?.map((data, index) => (
              <div key={index} className="nms_grouped_notes_box">
                <div className="d-flex justify">
                  <div className="d-flex label">
                    <GoPencil
                      size={12}
                      color={darkMode ? "#00ca69" : "#0a0a0a"}
                    />
                    <p>{data?.createdByName} Notes</p>
                  </div>
                  <div className="d-flex date-tag">
                    <p>{data?.createdOn}</p>
                    {data?.stageName === "Notes" ||
                    data?.stageName === "Comments" ? (
                      ""
                    ) : (
                      <p className="tag">{data?.stageName}</p>
                    )}
                  </div>
                </div>

                <div className="drug-action">
                  <p>{data?.elementName}</p>
                  <p>{data?.titleName}</p>
                </div>

                <div className="note-comments">
                  <p>{data?.value}</p>
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
}

export default NMSGroupedNotes;
