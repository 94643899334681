import React, { useState } from "react";
import "../assets/clinicalwarning.css";
import PropTypes from "prop-types";
import { ClinicalCheckActionTypes } from "../Model/ClinicalActions/ClinicalCheckActionTypes";
import { ClinicalCheckIdColor } from "../Model/ClinicalActions/ClinicalCheckIdColor";
import setCheckNow from "../Model/CheckNow/setCheckNow";
import useTitanApp from "../hooks/useTitanApp";
import {
  setCheckNowModel,
  setSeverityCount,
} from "../dispatchers/clinicalChecksDispatchers";

function PrescriptionClinicalWarning(props) {
  const { clincicalChecksState } = useTitanApp();

  const { isSkip, prescriptions, checkNowModel, severityCount } =
    clincicalChecksState || {};
  const [checked, setChecked] = useState("");
  const [isdisabled, setisdisabled] = useState(false);

  const [activeButton, setActiveButton] = useState("");
  const { item } = props || {};
  const {
    title,
    description,
    checkSeverity,
    clinicalCheckId,
    clinicalCheckOptions,
  } = item || {};

  const { prescriptionId } = prescriptions || {
    prescriptionId: "00000000-0000-0000-0000-000000000000",
  };

  function handleAction(clinicalCheckOptionId) {
    const prescriptionCheckActions = {
      clinicalCheckId: clinicalCheckId,
      clinicalCheckOptionId: clinicalCheckOptionId,
      prescriptionId: prescriptionId,
    };
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      prescriptionCheckActions,
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      false
    );
    setCheckNowModel(newCheckNowModel);
    setCSSValues(clinicalCheckOptionId);

    if (checkSeverity == 2) {
      // Check If the checks are mandatory
      setSeverityCount(severityCount - 1);
    }
  }
  function setCSSValues(clinicalCheckOptionId) {
    setActiveButton(clinicalCheckOptionId);
    setChecked("-checked");
    setisdisabled(true);
  }
  const ResultComponent = () => {
    return (
      <>
        {description ? (
          <div
            className={`p-message-warn-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
          >
            <p>{description}</p>
            <div className="p-message-inner">
              <button
                className={`warn-button-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
              >
                {title}
              </button>
              {isSkip ? (
                <></>
              ) : (
                <span>
                  {clinicalCheckOptions?.map((key) => {
                    const isButtonActive =
                      key?.clinicalCheckOptionId === activeButton;
                    return (
                      <button
                        disabled={isdisabled}
                        className={`issue-button${
                          isButtonActive ? "-active" : ""
                        }`}
                        onClick={() => handleAction(key?.clinicalCheckOptionId)}
                      >
                        {ClinicalCheckActionTypes[key?.clinicalCheckActionType]}
                      </button>
                    );
                  })}
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return <ResultComponent />;
}
PrescriptionClinicalWarning.propTypes = {
  item: PropTypes.any.isRequired,
};
export default PrescriptionClinicalWarning;
