import { queryByAltText } from "@testing-library/react";

class PrecriptionItemNotes {
  constructor(response, Type, PrescriptionItemNoteId, TypeValue) {
    this.obj = response?.map((value) => {
      return {
        professionalName: value.professionalName,
        prescriptionItemNoteId: value.prescriptionItemNoteId,
        prescriptionItemId: value.prescriptionItemId,
        dispenseItemId: value.dispenseItemId,
        note: value.note,
        archiveDate: value.archiveDate,

        awaitingQuery:
          Type === "AwaitingQuery"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.awaitingQuery
            : value.awaitingQuery,
        pinNote:
          Type === "PinNote"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.pinNote
            : value.pinNote,
        showOnPicking:
          Type === "ShowOnPicking"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.showOnPicking
            : value.showOnPicking,
        showOnLabelling:
          Type === "ShowOnLabelling"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.showOnLabelling
            : value.showOnLabelling,
        showOnHandout:
          Type === "ShowOnHandout"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.showOnHandout
            : value.showOnHandout,
        needCheck:
          Type === "NeedCheck"
            ? value.prescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.needCheck
            : value.needCheck,
        createdOn: value.createdOn,
      };
    });
  }
}

export default PrecriptionItemNotes;
